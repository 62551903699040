import { Button, Center, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { mobileCheck } from '../../@utils/utils'
import { COLORS } from '../../assets/colors'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true })
    const navigate = useNavigate();
    return (
        <Center w={'100dvw'} h={'100dvh'} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
            <Text
                fontSize={isWeb ? '4xl' : '2xl'}
                color={COLORS.white}
                fontWeight={'bold'}
            >
                404 Not Found
            </Text>
            <Button colorScheme='telegram' mt={5} onClick={() => navigate('/', { replace: true })}>
                Go to Home
            </Button>
        </Center>
    )
}
