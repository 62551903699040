import React from 'react';
import { motion } from 'framer-motion';
import { Center, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { COLORS } from '../../../assets/colors';
import { mobileCheck } from '../../../@utils/utils';

interface Props {
    title: string;
    text: string;
    icon: string;
    index: number;
}

export default function AboutCard({ title, text, icon, index }: Props) {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    return (
        <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: .5, delay: 2 + index / 2 }}
        >
            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} p={5} borderRadius={10} background={COLORS.white} boxShadow={'0 0 10px rgba(0, 0, 0, .1)'} minH={isWeb ? 200 : 150}>
                <Center>
                    <img src={icon} alt={title} width={isWeb ? 50 : 30} height={isWeb ? 50 : 30} />
                </Center>
                <Text color={COLORS.black} fontSize={isWeb ? 20 : 15} mt={3} fontWeight={'bold'}>
                    {title}
                </Text>
                <Text color={COLORS.black} fontSize={isWeb ? 15 : 10} mt={3}>
                    {text}
                </Text>
            </Flex>
        </motion.div>
    )
}
