import { Button, Center, Flex, Input, Text, Textarea, useMediaQuery } from '@chakra-ui/react'
import React, { useState } from 'react'
import { mobileCheck } from '../../@utils/utils'
import { COLORS } from '../../assets/colors';
import Header from '../_components/Header';
import { FONT } from '../../assets/fonts';
import { useMutation } from '@tanstack/react-query';
import { postSupport } from '../../api/request';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { textData } from '../../atom/language';

type SupportSendValueTypes = {
    exchangeId: string,
    content: string,
    email: string
}

export default function Support() {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const { Support: text } = useRecoilValue(textData);
    const { mutate, isPending } = useMutation({
        mutationKey: ['send_support'],
        mutationFn: postSupport,
        onSuccess(data) {
            console.log(data, 'data');
            toast(text[1](), { type: 'success' });
            setSupportSendValue({
                exchangeId: '',
                content: '',
                email: ''
            });
        },
        onError(error) {
            console.log(error, 'error');
            toast(text[2](), { type: 'error' });
        }
    })
    const [supportSendValue, setSupportSendValue] = useState<SupportSendValueTypes>({
        exchangeId: '',
        content: '',
        email: ''
    });

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSupportSendValue({
            ...supportSendValue,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        const { exchangeId, content, email } = supportSendValue;

        console.log(supportSendValue);

        if (!exchangeId || !content || !email) {
            toast(text[3](), { type: 'error' });
            return;
        }

        mutate({
            exchangeId,
            email,
            content
        })
    }

    return (
        <Flex minW={'100%'} minH={'100dvh'} flexDirection={'column'}>
            <Header />
            <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
                <Flex w={isWeb ? 800 : 'full'} backgroundColor={COLORS.white} h={isWeb ? 'fit-content' : '530px'} borderRadius={15} boxShadow={'xl'} paddingX={5} flexDirection={'column'} py={5}>
                    <Center>
                        <Text fontSize={isWeb ? 24 : 17} fontWeight={'bold'} fontFamily={FONT.TTHakgyoansimSamulhamR}>
                            {text[6]()}
                        </Text>
                    </Center>
                    <Input
                        placeholder={text[7]()}
                        fontSize={isWeb ? 14 : 13}
                        name='email'
                        value={supportSendValue.email}
                        mt={5}
                        onChange={handleOnChange}
                    />
                    <Input
                        placeholder={text[8]()}
                        fontSize={isWeb ? 14 : 13}
                        name='exchangeId'
                        value={supportSendValue.exchangeId}
                        mt={5}
                        onChange={handleOnChange}
                    />
                    <Textarea
                        mt={5}
                        resize={'none'}
                        placeholder={text[4]()}
                        minH={isWeb ? 200 : 100}
                        fontSize={isWeb ? 14 : 13}
                        name='content'
                        value={supportSendValue.content}
                        flex={isWeb ? 0 : 1}
                        onChange={handleOnChange}
                    />
                    <Button
                        mt={5}
                        colorScheme='whatsapp'
                        isLoading={isPending}
                        onClick={onSubmit}
                    >
                        {text[5]()}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}
