import { Box, Button, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import React, { useState } from 'react'
import { mobileCheck } from '../../@utils/utils';
import { COLORS } from '../../assets/colors';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { language } from '../../atom/language';

export default function LanguageButton() {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [lang, setLang] = useRecoilState(language);

    const onChangeLanguage = (lang: 'en' | 'ko' | 'jp') => {
        localStorage.setItem('lang', lang);
        setLang(lang);
        setIsOpen(false);
    }

    return (
        <Box position={'relative'}>
            <Button w={isWeb ? 100 : 'full'} justifyContent={isWeb ? 'space-around' : 'center'} colorScheme='twitter' borderBottomRadius={isOpen ? 0 : 5} onClick={() => setIsOpen(!isOpen)}>
                <img src={require(`../../assets/icon/${lang}.png`)} width={20} />
                <Text ml={isWeb ? 0 : 5}>{lang.toUpperCase()}</Text>
            </Button>
            {
                isOpen && (
                    <VStack w={'full'} position={'absolute'} zIndex={999} gap={0} backgroundColor={COLORS.white} borderBottomRadius={5} overflow={'hidden'}>
                        <Button w={isWeb ? 100 : 'full'} justifyContent={isWeb ? 'space-around' : 'center'} borderRadius={0} onClick={() => onChangeLanguage('en')}>
                            <img src={require('../../assets/icon/en.png')} width={20} />
                            <Text ml={isWeb ? 0 : 5}>EN</Text>
                        </Button>
                        <Button w={isWeb ? 100 : 'full'} justifyContent={isWeb ? 'space-around' : 'center'} borderRadius={0} onClick={() => onChangeLanguage('ko')}>
                            <img src={require('../../assets/icon/ko.png')} width={20} />
                            <Text ml={isWeb ? 0 : 5}>KO</Text>
                        </Button>
                        <Button w={isWeb ? 100 : 'full'} justifyContent={isWeb ? 'space-around' : 'center'} borderRadius={0} onClick={() => onChangeLanguage('jp')}>
                            <img src={require('../../assets/icon/jp.png')} width={20} />
                            <Text ml={isWeb ? 0 : 5}>JP</Text>
                        </Button>
                    </VStack>
                )
            }
        </Box>
    )
}
