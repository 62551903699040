import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil';
import { language } from '../atom/language';

export default function LanguageSetting({ children }: { children: React.ReactNode }) {
    const setLang = useSetRecoilState(language);

    useEffect(() => {
        const lang = localStorage.getItem('lang') as 'en' | 'ko';

        if (lang !== 'en' && lang !== 'ko') {
            localStorage.setItem('lang', 'en');
            setLang('en');
        }

        if (lang) {
            setLang(lang);
        } else {
            setLang('en');
            localStorage.setItem('lang', 'en');
        }
    }, [])

    return (
        <>{children}</>
    )
}
