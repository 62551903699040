import React from 'react'
import { IconProps } from '../assetTypes'
import { COLORS } from '../colors'

export default function Twitter({ color = COLORS.white, size = 32 }: IconProps) {
    return (
        <svg width={`${size}`} height={`${size}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9467 7.99999C28.92 8.46666 27.8133 8.77333 26.6667 8.92C27.84 8.21333 28.7467 7.09333 29.1733 5.74666C28.0667 6.41333 26.84 6.88 25.5467 7.14666C24.4933 6 23.0133 5.33333 21.3333 5.33333C18.2 5.33333 15.64 7.89333 15.64 11.0533C15.64 11.5067 15.6933 11.9467 15.7867 12.36C11.04 12.12 6.81333 9.83999 4 6.38666C3.50666 7.22666 3.22666 8.21333 3.22666 9.25333C3.22666 11.24 4.22666 13 5.77333 14C4.82666 14 3.94666 13.7333 3.17333 13.3333V13.3733C3.17333 16.1467 5.14666 18.4667 7.76 18.9867C6.92097 19.2163 6.04012 19.2482 5.18666 19.08C5.5488 20.2166 6.25805 21.2112 7.21468 21.9239C8.17132 22.6366 9.32726 23.0316 10.52 23.0533C8.49817 24.6539 5.99199 25.5191 3.41333 25.5067C2.96 25.5067 2.50666 25.48 2.05333 25.4267C4.58666 27.0533 7.6 28 10.8267 28C21.3333 28 27.1067 19.28 27.1067 11.72C27.1067 11.4667 27.1067 11.2267 27.0933 10.9733C28.2133 10.1733 29.1733 9.16 29.9467 7.99999Z" fill={color} />
        </svg>

    )
}
