import { Box, Center, Flex, Grid, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { mobileCheck } from '../../@utils/utils'
import { COLORS } from '../../assets/colors';
import Header from '../_components/Header';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AboutCard from './_component/AboutCard';
import { useRecoilValue } from 'recoil';
import { textData } from '../../atom/language';

export default function About() {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const navigate = useNavigate();
    const { About: text } = useRecoilValue(textData);

    const aboutText = [
        {
            title: text[1](),
            text: text[2](),
            icon: require('../../assets/icon/trust.png')
        },
        {
            title: text[3](),
            text: text[4](),
            icon: require('../../assets/icon/fast.png')
        },
        {
            title: text[5](),
            text: text[6](),
            icon: require('../../assets/icon/feedback.png')
        },
        {
            title: text[7](),
            text: text[8](),
            icon: require('../../assets/icon/safety.png')
        }
    ]

    return (
        <>
            <Header />
            <Flex w={'100%'} minH={'100dvh'}>
                <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} alignItems={'center'}>
                    <Flex w={'90%'} h={'full'} flexDirection={'column'} mt={isWeb ? 0 : 120} marginTop={120}>
                        <motion.div
                            initial={{ display: 'flex', opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: .5 }}
                        >
                            <Text color={COLORS.white} onClick={() => navigate('/')} cursor={'pointer'} _hover={{ color: COLORS.gray }}>
                                Swabee&nbsp;
                            </Text>
                            <Text color={COLORS.white}>
                                | {text[11]()}
                            </Text>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: .5, delay: .5 }}
                        >
                            <Text color={COLORS.white} fontSize={isWeb ? 40 : 30}>
                                {text[12]()}
                            </Text>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: .5, delay: 1 }}
                        >
                            <Box w={isWeb ? '60%' : '100%'} mt={isWeb ? 5 : 3}>
                                <Text color={COLORS.white} fontSize={isWeb ? 17 : 13}>
                                    {text[13]()}
                                </Text>
                            </Box>
                        </motion.div>
                        <Grid templateColumns={isWeb ? '2fr 2fr' : '1fr'} gap={5} mt={10} mb={isWeb ? 5 : 10}>
                            {
                                aboutText.map((item, idx) => (
                                    <AboutCard
                                        key={idx}
                                        title={item.title}
                                        text={item.text}
                                        icon={item.icon}
                                        index={idx}
                                    />
                                ))
                            }
                        </Grid>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
