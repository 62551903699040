import React from 'react'
import Lottie from 'react-lottie';

interface Props {
    size?: number | string
}

export default function Loading({ size = '100%' }: Props) {
    return (
        <Lottie
            options={{
                animationData: require('./loading.json'),
                loop: true,
                autoplay: true,
            }}
            isPaused={false}
            isStopped={false}
            width={size}
        />
    )
}
