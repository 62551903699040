import { ChakraProvider } from "@chakra-ui/react";
import Router from "./pages/Router";
import ReactQueryProvider from "./provider/ReactQueryProvider";
import { RecoilRoot } from "recoil";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import LanguageSetting from "./provider/LanguageSetting";
import './@utils/firebase-messaging-sw';
import { CookiesProvider } from "react-cookie";
import { getFcmToken, requestPermission } from "./@utils/firebase-messaging-sw";
import Footer from "./pages/_components/Footer";

export default function App() {
  useEffect(() => {
    // 빌드 이후 콘솔 전부 지우기
    if (process.env.NODE_ENV === "production") {
      console = window.console || {};
      console.log = function no_console() { };
      console.warn = function no_console() { };
      console.error = function () { };
    }

    requestPermission();
    getFcmToken().then((token) => {
      console.log('token', token);
    });
  }, [])



  return (
    <ReactQueryProvider>
      <ChakraProvider>
        <ToastContainer style={{ zIndex: 999999 }} />
        <CookiesProvider>
          <RecoilRoot>
            <LanguageSetting>
              <Router />
              <Footer />
            </LanguageSetting>
          </RecoilRoot>
        </CookiesProvider>
      </ChakraProvider>
    </ReactQueryProvider>
  )
}
