import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { mobileCheck } from '../@utils/utils';
import { COLORS } from '../assets/colors';
import { useRecoilValue } from 'recoil';
import { textData } from '../atom/language';

interface ModalProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    valueCheck: string,
    setValueCheck: React.Dispatch<React.SetStateAction<string>>,
    onSubmit?: () => void
}


export default function PasswordModal({ isOpen, setIsOpen, value, setValue, valueCheck, setValueCheck, onSubmit }: ModalProps) {

    const onClose = () => setIsOpen(false);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const { PasswordModal: text } = useRecoilValue(textData);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'password') {
            setValue(e.target.value.trim());
        } else {
            setValueCheck(e.target.value.trim());
        }
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{text[1]()}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input
                        placeholder={text[2]()}
                        fontSize={isWeb ? 14 : 13}
                        value={value}
                        onChange={onChange}
                        type='password'
                        name='password'
                    />
                    <Input
                        placeholder={text[3]()}
                        fontSize={isWeb ? 14 : 13}
                        value={valueCheck}
                        onChange={onChange}
                        type='password'
                        name='passwordCheck'
                        mt={5}
                    />
                    <Text mt={5} fontSize={isWeb ? 13 : 12} color={COLORS.gray}>
                        {text[4]()}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>{text[5]()}</Button>
                    <Button onClick={onSubmit} ml={3} colorScheme='whatsapp'>{text[6]()}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
