import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { TabContext } from '../../../provider/TabProvider'
import { COLORS } from '../../../assets/colors';
import { motion } from 'framer-motion';
import { FONT } from '../../../assets/fonts';
import { mobileCheck } from '../../../@utils/utils';

export default function Tab() {

    const { tab, setTab } = useContext(TabContext);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    const handleTab = (value: 'exchange' | 'buy') => {
        setTab(value);
    }

    return (
        <Flex w={'full'} h={70} position={'relative'}>
            {/* <motion.div
                initial={{ position: 'absolute', width: '100%', height: '5px', backgroundColor: 'yellow', borderRadius: 10 }}
            /> */}
            <Flex w={'100%'} h={'full'} justifyContent={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => handleTab('exchange')}>
                <Text fontSize={isWeb ? 20 : 13} fontFamily={FONT.TTHakgyoansimSamulhamR} fontWeight={'bold'} color={tab === 'exchange' ? COLORS.black : COLORS.gray}>
                    Exchange
                </Text>
            </Flex>
            {/* <Flex w={'50%'} h={'full'} justifyContent={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => handleTab('buy')}>
                <Text fontSize={isWeb ? 20 : 13} fontFamily={FONT.TTHakgyoansimSamulhamR} fontWeight={'bold'} color={tab === 'buy' ? COLORS.black : COLORS.gray}>
                    Buy
                </Text>
            </Flex> */}
        </Flex>
    )
}
