import React, { useEffect, useState } from 'react'
import { CoinTypes } from '../@types/coinTypes'
import { useRecoilState } from 'recoil'
import { receiveCoinAtom, selectedCoinAtom } from '../atom/coinAtom'

export default function useSelectedCoin() {

    const [selectedCoinValue, setSelectedCoinValue] = useRecoilState(selectedCoinAtom);
    const [receiveCoinValue, setReceiveCoinValue] = useRecoilState(receiveCoinAtom);

    const handleChangedCoin = () => {
        const copySendData = { ...selectedCoinValue };
        const copyReceiveData = { ...receiveCoinValue };

        setReceiveCoinValue(copySendData);
        setSelectedCoinValue(copyReceiveData);
    }

    const handleSelectedCoin = (coin: CoinTypes, type: 'send' | 'receive') => {
        if (type === 'send') {
            if (receiveCoinValue.name === coin.name) {
                setReceiveCoinValue(selectedCoinValue);
                setSelectedCoinValue(coin);
                return;
            }

            setSelectedCoinValue(coin);
        } else {
            if (selectedCoinValue.name === coin.name) {
                setSelectedCoinValue(receiveCoinValue);
                setReceiveCoinValue(coin);
                return;
            }

            setReceiveCoinValue(coin);
        }
    }

    return { selectedCoinValue, receiveCoinValue, handleChangedCoin, handleSelectedCoin }
}