import { Flex } from '@chakra-ui/react'
import axios from 'axios'
import React, { ReactElement, useEffect } from 'react'
import Header from '../_components/Header'
import ExchangeSection from './_components/ExchangeSection'
import { useQuery } from '@tanstack/react-query'
import { getCoinList } from '../../api/request'
import AboutSection from '../_components/AboutSection'
import RouteTracker from '../../hooks/RouteTracker'
import ReviewSection from './_components/ReviewSection'

export default function Home(): ReactElement {
    return (
        <>
            <Flex w={'100%'} minH={'100dvh'} flexDirection={'column'}>
                <Header />
                <ExchangeSection />
            </Flex>
            <AboutSection />
            <ReviewSection />
        </>
    )
}
