import { Box, Button, Center, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { COLORS } from '../../assets/colors'
import { mobileCheck } from '../../@utils/utils';
import Telegram from '../../assets/icon/Telegram';
import Discord from '../../assets/icon/Discord';
import Twitter from '../../assets/icon/Twitter';

export default function Footer() {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    return (
        <>
            <Flex background={`linear-gradient(to right, ${COLORS.footer_color.join(',')})`} justifyContent={'space-between'} px={10} py={isWeb ? 10 : 5}>
                <Center flexDirection={'column'} w={'full'}>
                    <img src={require('../../assets/icon/logo.png')} width={150} />
                    <Text color={COLORS.white} fontSize={13}>
                        Best Crypto Exchange For You!
                    </Text>
                    <Flex mt={5}>
                        <Button p={0} background={'transparent'} _hover={{ background: COLORS.gray }}>
                            <Telegram />
                        </Button>
                        <Button p={0} background={'transparent'} _hover={{ background: COLORS.gray }} mx={5}>
                            <Twitter />
                        </Button>
                        <Button p={0} background={'transparent'} _hover={{ background: COLORS.gray }}>
                            <Discord />
                        </Button>
                    </Flex>
                </Center>
            </Flex>
            <Center backgroundColor={COLORS.black} py={5}>
                <Text color={COLORS.white} fontSize={isWeb ? 14 : 11}>
                    Copyright © 2024 • Swabee
                </Text>
            </Center>
        </>
    )
}
