import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Home from './home/page'
import MyExchange from './my_exchange/page'
import ExchangeNew from './exchange/new/page'
import Confirmation from './exchange/confirmation/page'
import Exchange from './exchange/page'
import Support from './support/page'
import RouteTracker from '../hooks/RouteTracker'
import NotFound from './home/not-found'
import ErrorPage from './error/page'
import About from './about/page'

export default function Router() {
    return (
        <BrowserRouter>
            <RouteTracker />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/my_exchange' element={<MyExchange />} />
                <Route path='/about' element={<About />} />
                <Route path='/exchange/new' element={<ExchangeNew />} />
                <Route path='/exchange/confirmation' element={<Confirmation />} />
                <Route path='/exchange/checking' element={<Exchange />} />
                <Route path='/support' element={<Support />} />
                <Route path='/error' element={<ErrorPage />} />
                <Route path='/*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
