import { Box, Button, Flex, Input, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../../assets/colors'
import { CoinTypes } from '../../../@types/coinTypes'
import { SearchTabTypes } from '../../../@types/utilType'
import SearchDrop from './SearchDrop'
import { getCoinList, getExchangePrice } from '../../../api/request'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Loading from '../../../assets/lottie/Loading'
import { getSymbolUrl, mobileCheck } from '../../../@utils/utils'
import ArrowIcon from '../../../assets/icon/ArrowIcon'
import MobileSelectedModal from '../../../@modal/MobileSelectedModal'
import { ExchangeFeeResponse, ExchangePriceResponse } from '../../../@types/responseTypes'
import useSelectedCoin from '../../../hooks/useSelectedCoin'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import { language } from '../../../atom/language'
import axios from 'axios'

interface Props {
    type: 'send' | 'receive',
    targetCoin: CoinTypes,
    searchTab: SearchTabTypes,
    setSearchTab: React.Dispatch<React.SetStateAction<SearchTabTypes>>,
    requestPrice?: number,
    setRequestPrice?: React.Dispatch<React.SetStateAction<number>>,
    receiveCoin: CoinTypes
}

export default function ExchangeInput({ type, targetCoin, searchTab, setSearchTab, requestPrice, setRequestPrice }: Props) {
    const [mobileSelectedModalOpen, setMobileSelectedModalOpen] = useState<boolean>(false);
    const { receiveCoinValue, selectedCoinValue } = useSelectedCoin();
    const { data: exchangePrice, isLoading, isFetching, isError, isSuccess, error } = useQuery<ExchangePriceResponse>({
        queryKey: ['anticipated_price', `${requestPrice}`, `${targetCoin?.symbol}`],
        queryFn: () => getExchangePrice(selectedCoinValue.symbol, `${requestPrice}`, receiveCoinValue.symbol),
        enabled: type === 'receive' && targetCoin.symbol !== '' && Number.isNaN(requestPrice) === false,
        retry: 0,
    })
    const lang = useRecoilValue(language);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const queryClient = useQueryClient();

    const receiveFeeData = queryClient.getQueryData<ExchangeFeeResponse>(['exchange_fee_receive', receiveCoinValue.symbol]);
    const loading = isLoading || isFetching;
    const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!setRequestPrice) return;
        setRequestPrice(parseFloat(e.target.value.trim()));
    }

    // useEffect(() => {
    //     if (searchTab === '') {
    //         queryClient.invalidateQueries({ queryKey: ['coinList'] });
    //     }
    // }, [searchTab])

    if (isError) {
        if (axios.isAxiosError(error)) {
            if (error.response?.data.message === "You've exceeded the maximum amount you can currently purchase.") {
                toast('You have exceeded the maximum amount you can currently purchase.', { type: 'error' });
                if (setRequestPrice) {
                    setRequestPrice(0.1);
                }
            }
        }
    }

    if (searchTab === type) {
        return <SearchDrop type={type} setSearchTab={setSearchTab} />
    }

    return (
        <Box w={isWeb ? '45%' : '100%'} position={'relative'}>
            <Flex w={'100%'} h={isWeb ? '70px' : '50px'} backgroundColor={COLORS.light_gray} borderRadius={10} p={2} justifyContent={'space-between'} alignItems={'center'}>
                <MobileSelectedModal
                    isOpen={mobileSelectedModalOpen}
                    setIsOpen={setMobileSelectedModalOpen}
                    type={type}
                />
                <Box flex={1}>
                    <Text fontSize={isWeb ? 13 : 8} color={COLORS.gray}>
                        {type === 'send' ? 'Send' : 'Get'} {targetCoin?.name}
                    </Text>
                    {
                        loading ?
                            <Flex w='full' h={'full'} alignItems={'center'}>
                                <Loading size={isWeb ? 90 : 40} />
                            </Flex>
                            :
                            <Box>
                                <Input
                                    border={'none'}
                                    _focusVisible={{ outline: 'none' }}
                                    p={0}
                                    h={isWeb ? 10 : 5}
                                    fontSize={isWeb ? 15 : 12}
                                    color={COLORS.black}
                                    type={type === 'send' ? 'number' : 'text'}
                                    disabled={type === 'receive' ? true : false}
                                    _disabled={{ color: COLORS.black }}
                                    value={type === 'send' ? requestPrice : undefined}
                                    onChange={handleChangePrice}
                                    fontWeight={'bold'}
                                    defaultValue={type === 'send' ? requestPrice : `≈${exchangePrice?.estimate_amount as number > 0 ? exchangePrice?.estimate_amount : 0}`}
                                />

                            </Box>
                    }
                </Box>
                <Button
                    mx={2}
                    backgroundColor={COLORS.white}
                    _hover={{ backgroundColor: COLORS.white, opacity: .8 }}
                    onClick={(e) => {
                        // console.log(targetCoin);
                        e.stopPropagation();
                        if (isWeb) {
                            setSearchTab(type);
                        } else {
                            setMobileSelectedModalOpen(true);
                        }
                    }}
                >
                    <Flex alignItems={'center'}>
                        <Box borderRadius={'50px'} overflow={'hidden'}>
                            <img crossOrigin="anonymous" src={getSymbolUrl(targetCoin?.logo_url)} alt={targetCoin?.name} width={isWeb ? 20 : 15} />
                        </Box>
                        <Text ml={isWeb ? 2 : 1} mr={1} fontSize={isWeb ? 17 : 10}>
                            {targetCoin?.symbol}
                        </Text>
                        <ArrowIcon direction='down' size={13} />
                    </Flex>
                </Button>
            </Flex>
            {
                (exchangePrice?.estimate_amount as number <= 0 && isWeb && type === 'receive') &&
                // (exchangePrice?.estimate_amount as number <= 0 && isWeb && type === 'receive' && Number(exchangePrice?.withdrawal_min_amount) > Number(exchangePrice?.estimate_amount)) &&
                <Box position={'absolute'} zIndex={98} bottom={'-45px'} backgroundColor={'rgba(244, 56, 56, 0.8)'} w={'full'} py={2} px={3} borderRadius={10}>
                    <Text color={'#fff'} fontSize={13}>
                        {
                            lang === 'ko'
                                ? `최소 출금액은 ${exchangePrice?.withdrawal_min_amount} ${receiveCoinValue.symbol} 입니다.`
                                :
                                lang === 'en' ? `minimum withdrawal amount is ${exchangePrice?.withdrawal_min_amount} ${receiveCoinValue.symbol}`
                                    : `最小出金額は${exchangePrice?.withdrawal_min_amount} ${receiveCoinValue.symbol}です。`
                        }
                    </Text>
                </Box>
            }
        </Box>
    )
}
