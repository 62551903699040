import React, { useEffect, useState } from 'react'
import { StatusType } from '../../../@types/statusTypes'
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { COLORS } from '../../../assets/colors'
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { mobileCheck } from '../../../@utils/utils';


export default function StatusList({ status, title, finish }: StatusType) {

    const location = useLocation();

    const [path, setPath] = useState<string>(location.pathname);
    const [past, setPast] = useState<string[]>([]);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    const nowStatus = {
        '/exchange/new': 'new',
        '/exchange/confirmation': 'confirmation',
        '/exchange/checking/:id': 'exchange',
        '/exchange/finish/:id': 'finish',
    }[status]

    const pastStatus = () => {
        if (path === '/exchange/new') {
            setPast([]);
        };

        if (path === '/exchange/confirmation') {
            setPast(['/exchange/new']);
        }

        if (path.includes('/exchange/checking')) {
            setPast(['/exchange/new', '/exchange/confirmation']);
        }

        if (path === '/exchange/finish/:id') {
            setPast(['/exchange/new', '/exchange/confirmation', '/exchange/checking']);
        }
    }

    useEffect(() => {
        pastStatus();
        setPath(location.pathname);
        if (path === status || (nowStatus === 'exchange' && path.includes('/exchange/checking'))) {
            console.log('status is', status, path);
        }
    }, [location.pathname])

    return (
        <>
            <Flex
                justifyContent={'center'}
                alignItems={'center'}
                backgroundColor={
                    finish ? '#22c35e'
                        :
                        past.includes(status) ?
                            '#22c35e'
                            : (path === status || (nowStatus === 'exchange' && path.includes('/exchange/checking')))
                                ? '#22c35e'
                                : COLORS.gray
                }
                h={'full'}
                borderRadius={5}
                px={isWeb ? 5 : 2}
            >
                <Text
                    color={COLORS.white}
                    fontWeight={'400'}
                    fontSize={isWeb ? 'md' : '11px'}
                >
                    {title}
                </Text>
            </Flex>
            {
                nowStatus !== 'finish' &&
                <Box flex={1} h={1} backgroundColor={finish ? '#22c35e' : past.includes(status) ? '#22c35e' : COLORS.light_gray} overflow={'hidden'}>
                    {
                        (path === status || (nowStatus === 'exchange' && path.includes('/exchange/checking'))) &&
                        <>
                            <motion.div
                                initial={{ width: 0, translateX: -1000 }}
                                animate={{ width: '100%', translateX: 300 }}
                                transition={{ duration: 2, repeat: Infinity }}
                                style={{ height: '100%', backgroundColor: '#22c35e' }}
                            />
                        </>
                    }
                </Box>
            }
        </>
    )
}
