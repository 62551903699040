import { Button, Center, Flex, useMediaQuery } from '@chakra-ui/react'
import React, { ReactElement, SetStateAction, useEffect, useState } from 'react'
import ExchangeInput from './ExchangeInput'
import SwapIcon from '../../../assets/icon/SwapIcon'
import { COLORS } from '../../../assets/colors'
import useSelectedCoin from '../../../hooks/useSelectedCoin'
import { SearchTabTypes } from '../../../@types/utilType'
import { mobileCheck } from '../../../@utils/utils'
import { useQuery } from '@tanstack/react-query'
import { CoinTypes } from '../../../@types/coinTypes'
import { getCoinList } from '../../../api/request'
import Loading from '../../../assets/lottie/Loading'
import { toast } from 'react-toastify'

interface Props {
    requestPrice: number,
    setRequestPrice: React.Dispatch<SetStateAction<number>>
}

export default function ExchangeInputSection({ requestPrice, setRequestPrice }: Props): ReactElement {
    const { selectedCoinValue, receiveCoinValue, handleChangedCoin, handleSelectedCoin } = useSelectedCoin();
    const [searchTab, setSearchTab] = useState<SearchTabTypes>('');
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    const { data: coinList, isLoading, isSuccess, isError } = useQuery<CoinTypes[] | undefined>({
        queryKey: ['coinList'],
        queryFn: getCoinList,
        staleTime: 1000000000,
    })

    if (isError) {
        toast('Failed to load coin list', { type: 'error' });
    }

    useEffect(() => {
        if (isSuccess && coinList && selectedCoinValue.network?.name?.length === 0 && receiveCoinValue.network?.name?.length === 0) {
            handleSelectedCoin(coinList[0], 'send');
            handleSelectedCoin(coinList[1], 'receive');
        }
    }, [isSuccess])

    if (isLoading) {
        return (
            <Center flex={1}>
                <Loading size={120} />
            </Center>
        )
    }

    return (
        <Flex flex={1} flexDirection={isWeb ? 'row' : 'column'} justifyContent={'space-between'} alignItems={isWeb ? 'center' : 'flex-end'} onClick={() => setSearchTab('')}>
            <ExchangeInput type={'send'} targetCoin={selectedCoinValue} receiveCoin={receiveCoinValue} searchTab={searchTab} setSearchTab={setSearchTab} requestPrice={requestPrice} setRequestPrice={setRequestPrice} />
            <Button
                colorScheme={isWeb ? 'telegram' : 'white'}
                padding={isWeb ? 2 : 0}
                onClick={handleChangedCoin}
                size={isWeb ? 'xl' : 'sm'}
                borderWidth={isWeb ? 0 : 1}
                borderColor={COLORS.light_gray}
            >
                <SwapIcon color={isWeb ? COLORS.white : COLORS.black} size={isWeb ? 25 : 17} />
            </Button>
            <ExchangeInput type={'receive'} targetCoin={receiveCoinValue} receiveCoin={receiveCoinValue} searchTab={searchTab} setSearchTab={setSearchTab} requestPrice={requestPrice} setRequestPrice={setRequestPrice} />
        </Flex >
    )
}
