import { Box, Button, Center, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { Swiper, SwiperRef, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import { COLORS } from '../../../assets/colors';
import { mobileCheck } from '../../../@utils/utils';
import { FONT } from '../../../assets/fonts';
import ArrowIcon from '../../../assets/icon/ArrowIcon';
import { useRecoilValue } from 'recoil';
import { textData } from '../../../atom/language';


export default function UsingDescriptionSection() {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const swiper = useSwiper();
    const swiperRef = useRef<SwiperRef | null>(null);
    const { UsingDescriptionSection: text } = useRecoilValue(textData);

    const usingList = [
        {
            title: text[2](),
            text: text[3]()
        },
        {
            title: text[4](),
            text: text[5]()
        },
        {
            title: text[6](),
            text: text[7]()
        },
        {
            title: text[8](),
            text: text[9]()
        },
        {
            title: text[10](),
            text: text[11]()
        },
    ]

    return (
        <Center w={'full'} flexDirection={'column'} py={5} h={isWeb ? 400 : 'fit-content'} backgroundColor={'#212121'}>
            <Text color={COLORS.white} mb={10} fontSize={isWeb ? 40 : 20} mt={5} fontFamily={FONT.TTHakgyoansimSamulhamR}>{text[12]()}</Text>
            <Flex px={isWeb ? 20 : 5} w={'full'} flexDirection={isWeb ? 'row' : 'column'} alignItems={'center'} flex={1} mb={10} justifyContent={'space-between'}>
                {
                    usingList.map((item, index) => (
                        <React.Fragment key={item.title}>
                            <Flex w={isWeb ? '15%' : '100%'} h={'full'} backgroundColor={COLORS.white} px={5} py={5} borderRadius={10}>
                                <Flex flexDirection={'column'}>
                                    <Box>
                                        <Box>
                                            <Text fontFamily={FONT.TTHakgyoansimSamulhamR} fontSize={isWeb ? 20 : 15} fontWeight={'bold'}>{text[1]()} {index + 1}</Text>
                                        </Box>
                                        <Text fontSize={isWeb ? 15 : 13} fontWeight={'bold'} mt={5} mb={3}>{item.title}</Text>
                                        <Text fontSize={isWeb ? 14 : 12} >{item.text}</Text>
                                    </Box>
                                </Flex>
                            </Flex>
                            {
                                index !== 4 &&
                                <Box my={isWeb ? 0 : 5}>
                                    <ArrowIcon color={COLORS.light_gray} direction={isWeb ? 'right' : 'down'} />
                                </Box>
                            }
                        </React.Fragment>
                    ))
                }

            </Flex>
        </Center>
    )
}
