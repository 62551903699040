import { LanguageTypes } from "../@types/utilType";

export const en: LanguageTypes = {
    ExchangeSection: {
        1: () => "Best Crypto Exchange For You!",
        2: () => "Start Exchange"
    },
    Header: {
        1: () => "About",
        2: () => "My Exchange",
        3: () => "Support"
    },
    ExchangeNew: {
        1: () => "Exchange",
        2: (symbol) => `Enter the receipien's ${symbol} address`,
        3: (symbol) => `Do not provide a smart contract as your ${symbol} payout address`,
        4: () => `Refound address`,
        5: () => `Enter your refound address (Optional)`,
        6: () => `Next`,
        7: () => `Invalid wallet address`,
        8: () => `This Refound wallet address does not exist`,
    },
    Confirmation: {
        1: () => `Please check the terms and conditions`,
        2: () => `Passwords do not match`,
        3: () => `Please enter a password of at least 4 characters`,
        4: () => `You send`,
        5: () => `You Get`,
        6: () => `To address`,
        7: () => `Checking Address`,
        8: () => `I've read and agree to the Swabee`,
        9: () => `Back`,
        10: () => `Next`,
        11: () => `After the deposit is completed, the transaction time will take approximately 40 minutes or more`
    },
    PasswordModal: {
        1: () => `Setting Exchange password`,
        2: () => `Enter password`,
        3: () => `Enter password Check`,
        4: () => `The password is used to check the progress after the application`,
        5: () => `Close`,
        6: () => `Submit`,
    },
    Exchange: {
        1: () => `Copied to clipboard`,
        2: () => `Exchange is finished`,
        3: () => `Exchange ID: `,
        4: () => `Copy`,
        5: () => `You send:`,
        6: () => `To address:`,
        7: () => `You Get:`,
        8: () => `Recipient's address:`,
        9: () => `Support`,
        10: () => `Home`,
        11: () => `Status of Exchange`,
        12: () => `No created transactions matched`,
        13: () => `The Token is expired.`
    },
    MyExchange: {
        1: () => `Please fill all the fields`,
        2: () => `Exchange ID`,
        3: () => `Enter your exchange ID`,
        4: () => `Password`,
        5: () => `Enter Exchange Password`,
        6: () => `Check My Exchange`,
    },
    Error: {
        1: () => `Server under maintenance`,
        2: () => `We are currently conducting server maintenance. Please wait a moment`
    },
    Support: {
        1: () => `Support sent successfully`,
        2: () => `Failed to send support`,
        3: () => `Please fill in all fields`,
        4: () => `Please write down what you want to inquire about`,
        5: () => `Send`,
        6: () => `Support`,
        7: () => `Enter Email`,
        8: () => `Enter exchange ID`,
    },
    About: {
        1: () => `Trust`,
        2: () => `Swabee guarantees the highest stability as all transactions are conducted on the blockchain. Experience secure transactions.`,
        3: () => `Fast Transactions`,
        4: () => `Swabee searches for the most optimized exchange rates and transaction routes internally, providing fast and efficient transactions tailored to the user's needs.`,
        5: () => `Quick Feedback`,
        6: () => `Swabee respects user opinions and provides quick feedback, focusing on user convenience through constant updates.`,
        7: () => `Secure Service`,
        8: () => `Swabee does not store personal information and ensures anonymity for user safety.`,
        9: () => `Continuous Updates`,
        10: () => `Swabee continuously updates to enhance user convenience.`,
        11: () => `Introduction`,
        12: () => `Introduction Text`,
        13: () => `Swabee is a cryptocurrency trading platform designed with user security as a top priority. We proudly offer the best service that meets daily needs without hassle. Respecting privacy, Swabee operates without registration or restrictions, allowing users to easily exchange cryptocurrencies.`
    },
    UsingDescriptionSection: {
        1: () => `Steps`,
        2: () => `Select the currency to trade`,
        3: () => `Choose the token you want to exchange and check the estimated payment amount.`,
        4: () => `Enter wallet address`,
        5: () => `Enter the wallet address to send the payment.`,
        6: () => `Deposit`,
        7: () => `Transfer the amount to the provided wallet address.`,
        8: () => `Confirm payment`,
        9: () => `Ensure the amount is safely deposited.`,
        10: () => `Write a review`,
        11: () => `Write a review to inform the next user about your satisfactory transaction.`,
        12: () => `How to use Swabee`,
    },
    ReviewSection: {
        1: () => `User reviews`,
        2: () => `Write a 5-star review and enter a draw to win EML.`,
        3: () => `Write`,
    },
    CreateReviewModal: {
        1: () => `Review submitted`,
        2: () => `Failed to submit review`,
        3: () => `Failed to submit review`,
        4: () => `Please fill in all fields`,
        5: () => `Write a review`,
        6: () => `Rating`,
        7: () => `Email`,
        8: () => `Please enter your email`,
        9: () => `Transaction ID`,
        10: () => `Please enter your transaction ID`,
        11: () => `Name`,
        12: () => `Please enter your name`,
        13: () => `Review`,
        14: () => `Please write your review`,
        15: () => `Submit`,
    }
}