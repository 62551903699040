import firebase from "firebase";
import 'firebase/messaging';
import { firebaseConfig } from "./firebase";
import { toast } from "react-toastify";

firebase.initializeApp(firebaseConfig);

export const requestPermission = () => {
    if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        void Notification.requestPermission().then(async (permission) => {
            if (permission === 'granted') {
                // 권한 허용
                // messaging
                //     .getToken({ vapidKey: 'BKQm1VyF9swGZYvWSoWv3acNWz37so3ZNQh00LMYl9uVQGKIQE_ccTqnOlYAq3uABtkLKv74rvFaU6_c3thq99E' })
                //     .then((token: string) => {
                //         console.log(`푸시 토큰 발급 완료 : ${token}`)
                //         localStorage.setItem('webToken', token);
                //     })
                //     .catch((err) => {
                //         console.log(err, '푸시 토큰 가져오는 중에 에러 발생')
                //     })
                toast(`Notification permissions allowed`, { type: 'success' })
            } else if (permission === 'denied') {
                console.log('푸시 권한 차단');
                toast('Please allow me to notify you', { type: 'error' })
            }
        })


    }

}

export const getFcmToken = async () => {
    if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();

        const token = await messaging.getToken({ vapidKey: 'BKQm1VyF9swGZYvWSoWv3acNWz37so3ZNQh00LMYl9uVQGKIQE_ccTqnOlYAq3uABtkLKv74rvFaU6_c3thq99E' });

        messaging.onMessage(function (payload) {
            console.log(payload.notification.title);
            console.log(payload.notification.body);
            toast(payload.notification.body, { type: 'info' });
            return;
        })

        return token;
    } else {
        return '';
    }
}