import { Box, Button, Center, Checkbox, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { CoinTypes } from '../../@types/coinTypes';
import { mobileCheck } from '../../@utils/utils';
import StatusSection from './_component/StatusSection';
import Header from '../_components/Header';
import { COLORS } from '../../assets/colors';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../assets/lottie/Loading';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { Socket, io } from 'socket.io-client';
import { useRecoilValue } from 'recoil';
import { language, textData } from '../../atom/language';
import { useQuery } from '@tanstack/react-query';
import { userExchangeCheck } from '../../api/request';
import { ExchangeCheckResponse, SocketResponse } from '../../@types/responseTypes';
import { getFcmToken, requestPermission } from '../../@utils/firebase-messaging-sw';
import CreateReviewModal from '../../@modal/CreateReviewModal';
export default function Exchange() {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const [exchangeStatus, setExchangeStatus] = useState<string>('Awaiting deposit');
    const { state } = useLocation();
    const { Exchange: text, UsingDescriptionSection: reviewText } = useRecoilValue(textData);
    const navigate = useNavigate();
    const lang = useRecoilValue(language);
    const [reviewModal, setReviewModal] = useState<boolean>(false);

    const { data, isLoading, isError, isSuccess } = useQuery<ExchangeCheckResponse>({
        queryKey: ['exchange_state', state?.id],
        queryFn: () => userExchangeCheck({ id: state?.id, password: state?.password }),
        enabled: !!state?.id && !!state?.password
    })


    const parsingStatus = (status: string): string => {
        switch (status) {
            case 'WAITNG_DEPOSIT':
                return 'Awaiting deposit';
            case 'DEPOSITED':
                return 'Deposited';
            case 'PROCESSING':
                return 'Processing';
            case 'COMPLETE':
                return 'Finish';
            default:
                return 'Error';
        }
    }

    useEffect(() => {
        if (!state?.id || !state?.password) {
            console.log('back!', state?.id, state?.password);
            navigate('/', { replace: true });
        }

        if (data) {
            setExchangeStatus(parsingStatus(data?.exchange_data.exchange_status as string));
            const socket = io(process.env.REACT_APP_SOCKET_URL as string);

            connectSocket(socket);

            return () => {
                socket.disconnect();
                window.removeEventListener('beforeunload', () => {
                    socket.disconnect();
                });
            }
        }
    }, [data])

    if (!state?.id || !state?.password) {
        navigate('/', { replace: true });
        return (
            <></>
        )
    }

    if (isSuccess) {
        console.log(data, '데이터');
    }



    if (isError) {
        toast(text[12](), { type: 'error' });
        navigate('/my_exchange', { replace: true });
        return (
            <></>
        )
    }



    const connectSocket = async (socket: Socket) => {
        // 새로고침 시 socket disconnect
        window.addEventListener('beforeunload', () => {
            if (socket.connected) {
                socket.disconnect();
            }
        });

        const token = await getFcmToken();

        if (!socket.connected && data?.access_token) {
            console.log({
                exchange_id: state?.id,
                access_token: data?.access_token,
                fcm_token: token
            }, 'socket emit data');

            data?.access_token && socket.emit(`exchangeState`, {
                exchange_id: state?.id,
                access_token: data?.access_token,
                fcm_token: token
            })

            socket.on('exchangeState', (data: SocketResponse) => {
                console.log(data, 'socket 넘어옴');

                console.log(data.exchange_status);

                if (data.exchange_status === 'COMPLETE') {
                    console.log('SOCKET 제거')
                    socket.disconnect();
                }

                if (data.error) {
                    socket.disconnect();
                    if (data.error === 'The Token is expired.') {
                        toast(text[13](), { type: 'error' });
                        navigate('/', {
                            replace: true
                        })
                    }
                } else {
                    setExchangeStatus(parsingStatus(data.exchange_status));
                }
            })
        }
    }

    const showingStatus = (status: string): string => {
        if (lang === 'ko') {
            switch (status) {
                case 'Awaiting deposit':
                    return '입금 대기중';
                case 'Deposited':
                    return '입금 완료';
                case 'Processing':
                    return '처리중';
                case 'Finish':
                    return '완료';
                default:
                    return 'Error';
            }
        } else if (lang === 'en') {
            switch (status) {
                case 'Awaiting deposit':
                    return 'Awaiting deposit';
                case 'Deposited':
                    return 'Deposited';
                case 'Processing':
                    return 'Processing';
                case 'Finish':
                    return 'Complete';
                default:
                    return 'Error';
            }
        } else {
            switch (status) {
                case 'Awaiting deposit':
                    return '入金待ち';
                case 'Deposited':
                    return '入金完了';
                case 'Processing':
                    return '処理中';
                case 'Finish':
                    return '完了';
                default:
                    return 'Error';
            }
        }
    }

    const showingStatusDescription = (status: string): string => {
        if (lang === 'en') {
            switch (status) {
                case 'Awaiting deposit':
                    return 'Currently waiting for deposit. Processing will begin once the deposit is confirmed.';
                case 'Deposited':
                    return 'The deposit has been confirmed. Processing will begin.';
                case 'Processing':
                    return 'It is in progress. Please wait until it is completed.';
                case 'Finish':
                    return 'The transaction is complete.';
                default:
                    return 'Error';
            }
        } else if (lang === 'ko') {
            switch (status) {
                case 'Awaiting deposit':
                    return '현재 입금 대기중입니다. 입금이 확인되면 처리가 시작됩니다.';
                case 'Deposited':
                    return '입금이 확인되었습니다. 처리가 시작됩니다.';
                case 'Processing':
                    return '처리중입니다. 완료까지 기다려주세요.';
                case 'Finish':
                    return '거래가 완료되었습니다.';
                default:
                    return 'Error';
            }
        } else {
            switch (status) {
                case 'Awaiting deposit':
                    return '現在、入金待ちです。入金が確認されると処理が開始されます。';
                case 'Deposited':
                    return '入金が確認されました。処理が開始されます。';
                case 'Processing':
                    return '処理中です。完了までお待ちください。';
                case 'Finish':
                    return '取引が完了しました。';
                default:
                    return 'Error';
            }
        }
    }



    const onCopyToClipboard = async (type: 'address' | 'id') => {
        const copyText = type === 'address' ? data?.exchange_data.onchain_account.address : data?.exchange_data.id;

        await navigator.clipboard.writeText(copyText as string);
        toast(text[1](), { type: 'success' })
    }

    return (
        <Flex minW={'100%'} minH={'100dvh'} flexDirection={'column'}>
            <CreateReviewModal isOpen={reviewModal} setIsOpen={setReviewModal} />
            <Header />
            <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
                <Flex w={isWeb ? 1200 : 'full'} backgroundColor={COLORS.white} h={isWeb ? 'fit-content' : '580px'} borderRadius={15} boxShadow={'xl'} paddingX={5} flexDirection={'column'} py={5}>
                    {
                        isLoading || isError
                            ?
                            <Center h={'530px'}>
                                <Loading size={isWeb ? 150 : 80} />
                            </Center>
                            :
                            <>
                                <Box mt={5} borderBottomWidth={2} borderColor={COLORS.light_gray}>
                                    <StatusSection status={exchangeStatus} />
                                </Box>
                                <Flex alignItems={'center'} mt={5}>
                                    <Text fontSize={isWeb ? 20 : 11} fontWeight={'bold'}>{text[3]()} </Text>
                                    <Box px={3} py={2} ml={2} backgroundColor={COLORS.light_gray} borderRadius={5}>
                                        <Text fontWeight={'bold'} fontSize={isWeb ? 15 : 10}>{state?.id}</Text>
                                    </Box>
                                    <Button onClick={() => onCopyToClipboard('id')} ml={3} colorScheme='telegram' fontSize={isWeb ? 15 : 12} px={3} h={isWeb ? '40px' : '25px'}>
                                        {text[4]()}
                                    </Button>
                                </Flex>
                                <Flex py={isWeb ? 10 : 0} mt={5} flexDirection={'column'}>
                                    <Text color={COLORS.gray}>{text[11]()}</Text>
                                    <Flex mt={isWeb ? 3 : 1} alignItems={'center'}>
                                        <Text fontSize={isWeb ? 30 : 17} fontWeight={'bold'}>
                                            {showingStatus(exchangeStatus)}
                                        </Text>
                                        {
                                            (exchangeStatus !== 'Finish') &&
                                            exchangeStatus !== 'Error' &&
                                            <Box>
                                                <Loading size={isWeb ? 80 : 50} />
                                            </Box>
                                        }
                                        {
                                            (exchangeStatus === 'Error') &&
                                            <Box borderRadius={'50%'} backgroundColor={'red'} w={3} h={3} ml={5} />
                                        }
                                    </Flex>
                                    <Text fontSize={12} mt={isWeb ? 3 : 1} color={COLORS.gray}>
                                        {showingStatusDescription(exchangeStatus)}
                                    </Text>
                                </Flex>
                                <Flex borderBottomWidth={1} pb={isWeb ? 5 : 2} w={'full'} justifyContent={'space-between'} alignItems={'flex-end'} mt={isWeb ? 0 : 5}>
                                    <Box w={'full'}>
                                        <Box>
                                            <Text color={COLORS.gray} fontSize={isWeb ? 17 : 12}>{text[5]()}</Text>
                                            <Text color={COLORS.black} fontSize={isWeb ? 20 : 14} fontWeight={'bold'}>{data?.exchange_data.exchange_from_amount} {data?.exchange_data.exchange_from_asset.symbol}</Text>
                                        </Box>
                                        <Box mt={5}>
                                            <Text color={COLORS.gray} fontSize={isWeb ? 17 : 12}>{text[6]()}</Text>
                                            <Flex alignItems={'center'}>
                                                <Text color={COLORS.black} fontSize={isWeb ? 20 : 10} fontWeight={'bold'}>{data?.exchange_data.onchain_account.address}</Text>
                                                <Button
                                                    onClick={() => onCopyToClipboard('address')}
                                                    ml={3}
                                                    colorScheme='telegram'
                                                    fontSize={isWeb ? 15 : 12}
                                                    px={3}
                                                    h={isWeb ? '40px' : '25px'}
                                                >
                                                    <Text>
                                                        {text[4]()}
                                                    </Text>
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    {
                                        isWeb &&
                                        <QRCode value={data?.exchange_data.onchain_account.address as string} size={120} />
                                    }
                                </Flex>
                                <Flex pt={isWeb ? 5 : 2} w={'full'} h={isWeb ? 'fit-content' : 'full'} flexDirection={isWeb ? 'row' : 'column'} justifyContent={'space-between'} alignItems={isWeb ? 'flex-end' : 'flex-start'}>
                                    <Box>
                                        <Box>
                                            <Text color={COLORS.gray} fontSize={isWeb ? 17 : 12}>{text[7]()}</Text>
                                            <Text color={COLORS.black} fontSize={isWeb ? 20 : 14} fontWeight={'bold'}>≒{data?.exchange_data.exchange_to_amount} {data?.exchange_data.exchange_to_asset.symbol}</Text>
                                        </Box>
                                        <Box mt={5}>
                                            <Text color={COLORS.gray} fontSize={isWeb ? 17 : 12}>{text[8]()}</Text>
                                            <Text color={COLORS.black} fontSize={isWeb ? 20 : 11} fontWeight={'bold'}>{data?.exchange_data.receipient_address}</Text>
                                        </Box>
                                    </Box>
                                    <Flex w={isWeb ? 'initial' : 'full'} justifyContent={'space-between'} mt={isWeb ? 0 : 2}>
                                        <Button fontSize={isWeb ? 16 : 13} w={isWeb ? 150 : '45%'} onClick={() => navigate('/support', { replace: true })}>
                                            {text[9]()}
                                        </Button>
                                        <Button onClick={() => { navigate('/', { replace: true }) }} colorScheme='whatsapp' fontSize={isWeb ? 16 : 13} w={isWeb ? 150 : '45%'} ml={isWeb ? 3 : 0}>
                                            {text[10]()}
                                        </Button>
                                    </Flex>
                                    {
                                        exchangeStatus === 'Finish' &&
                                        <Button onClick={() => { setReviewModal(true) }} colorScheme='telegram' fontSize={isWeb ? 16 : 13} w={isWeb ? 150 : 'full'} mt={isWeb ? 0 : 2}>
                                            {reviewText[10]()}
                                        </Button>
                                    }
                                </Flex>
                            </>
                    }
                </Flex>
            </Flex>
        </Flex >
    )
}
