import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { mobileCheck } from '../../@utils/utils';
import { COLORS } from '../../assets/colors';
import { useRecoilValue } from 'recoil';
import { textData } from '../../atom/language';

export default function ErrorPage() {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const { Error: text } = useRecoilValue(textData);

    return (
        <Flex w={'100%'} h={'100dvh'} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
            <Text color={COLORS.white} fontSize={20} fontWeight={'bold'}>
                {text[1]()}
            </Text>
            <Text color={COLORS.white} fontSize={14} fontWeight={'bold'} mt={5}>
                {text[2]()}
            </Text>
        </Flex>
    )
}
