import { LanguageTypes } from "../@types/utilType";

export const jp: LanguageTypes = {
    ExchangeSection: {
        1: () => "あなたのための最高の暗号通貨取引所！",
        2: () => "取引を開始"
    },
    Header: {
        1: () => "紹介",
        2: () => "私の取引",
        3: () => "サポート"
    },
    ExchangeNew: {
        1: () => "取引",
        2: (symbol) => `${symbol} アドレスを入力してください`,
        3: (symbol) => `${symbol} 支払いアドレスにスマートコントラクトを提供しないでください`,
        4: () => `返金アドレス`,
        5: () => `返金アドレスを入力してください（任意）`,
        6: () => `次へ`,
        7: () => `そのウォレットアドレスは無効です`,
        8: () => `その返金ウォレットアドレスは存在しません`,
    },
    Confirmation: {
        1: () => `利用規約を確認してください`,
        2: () => `パスワードが一致しません`,
        3: () => `少なくとも4文字以上のパスワードを入力してください`,
        4: () => `送る`,
        5: () => `受け取る`,
        6: () => `アドレスへ`,
        7: () => `アドレスを確認中`,
        8: () => `規約に同意しました`,
        9: () => `戻る`,
        10: () => `次へ`,
        11: () => `入金完了後の取引時間は大体最大40分以上かかります`
    },
    PasswordModal: {
        1: () => `取引パスワード設定`,
        2: () => `パスワードを入力`,
        3: () => `パスワード確認`,
        4: () => `パスワードは申し込み後、進捗状況を確認するために使用されます`,
        5: () => `閉じる`,
        6: () => `提出`,
    },
    Exchange: {
        1: () => `クリップボードにコピーされました`,
        2: () => `取引が完了しました`,
        3: () => `取引ID: `,
        4: () => `コピー`,
        5: () => `送る：`,
        6: () => `アドレスへ：`,
        7: () => `受け取る：`,
        8: () => `受け取りアドレス：`,
        9: () => `サポート`,
        10: () => `ホーム`,
        11: () => `取引ステータス`,
        12: () => `その取引は存在しません`,
        13: () => `トークンが期限切れです`
    },
    MyExchange: {
        1: () => `すべてのフィールドに入力してください`,
        2: () => `取引ID`,
        3: () => `取引IDを入力してください`,
        4: () => `パスワード`,
        5: () => `取引パスワードを入力してください`,
        6: () => `私の取引を確認`,
    },
    Error: {
        1: () => `サーバーメンテナンス中`,
        2: () => `サーバーをメンテナンス中です。しばらくお待ちください`
    },
    Support: {
        1: () => `お問い合わせが正常に送信されました`,
        2: () => `お問い合わせの送信に失敗しました。もう一度お試しください`,
        3: () => `すべてのフィールドに入力してください`,
        4: () => `お問い合わせ内容を記入してください`,
        5: () => `送信`,
        6: () => `サポート`,
        7: () => `メールアドレスを入力してください`,
        8: () => `取引IDを入力してください`,
    },
    About: {
        1: () => `信頼`,
        2: () => `Swabeeは、すべての取引がブロックチェーン上で行われるため、最高の安定性を保証します。安全な取引を体験してください。`,
        3: () => `迅速な取引`,
        4: () => `Swabeeは、内部的にユーザーに最適化された為替レートと取引ルートを検索し、ユーザーのニーズに合わせて迅速かつ効率的な取引を提供します。`,
        5: () => `迅速なフィードバック`,
        6: () => `Swabeeはユーザーの意見を尊重し、迅速なフィードバックを提供し、継続的なアップデートを通じてユーザーの利便性を重視したサービスを提供します。`,
        7: () => `安全なサービス`,
        8: () => `Swabeeはユーザーの安全のために個人情報を保存せず、匿名性を保証します。`,
        9: () => `継続的なアップデート`,
        10: () => `Swabeeはユーザーの利便性を高めるために継続的にアップデートを行います。`,
        11: () => `紹介`,
        12: () => `紹介文`,
        13: () => `Swabeeは、ユーザーの安全性を最優先に考えた暗号通貨取引プラットフォームです。私たちは、日常のニーズを手間なく満たす最高のサービスを誇りに提供します。プライバシーを尊重し、登録や制限なしで運営されるSwabeeは、ユーザーが暗号通貨を簡単に交換できるようにします。`
    },
    UsingDescriptionSection: {
        1: () => `ステップ`,
        2: () => `取引する通貨を選択`,
        3: () => `交換するトークンを選択し、予想支払金額を確認します。`,
        4: () => `ウォレットアドレスを入力`,
        5: () => `送金するウォレットアドレスを入力してください。`,
        6: () => `入金`,
        7: () => `案内されたウォレットアドレスに金額を送金してください。`,
        8: () => `支払い確認`,
        9: () => `金額が安全に入金されたか確認してください。`,
        10: () => `レビューを書く`,
        11: () => `レビューを書いて、次のユーザーに満足のいく取引を知らせましょう。`,
        12: () => `Swabeeの使い方`,
    },
    ReviewSection: {
        1: () => `ユーザーレビュー`,
        2: () => `5つ星のレビューを書いて、抽選でEMLを獲得しましょう。`,
        3: () => `書く`,
    },
    CreateReviewModal: {
        1: () => `レビューが送信されました`,
        2: () => `レビューの送信に失敗しました`,
        3: () => `レビューの送信に失敗しました`,
        4: () => `全ての項目を入力してください`,
        5: () => `レビューを書く`,
        6: () => `評価`,
        7: () => `メールアドレス`,
        8: () => `メールアドレスを入力してください`,
        9: () => `取引ID`,
        10: () => `取引IDを入力してください`,
        11: () => `名前`,
        12: () => `名前を入力してください`,
        13: () => `レビュー`,
        14: () => `レビューを書いてください`,
        15: () => `送信`,
    }

}
