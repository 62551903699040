import { Box, Button, Checkbox, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { mobileCheck } from '../../../@utils/utils';
import Header from '../../_components/Header';
import { COLORS } from '../../../assets/colors';
import StatusSection from '../_component/StatusSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { CoinTypes } from '../../../@types/coinTypes';
import ArrowLineIcon from '../../../assets/icon/ArrowLineIcon';
import { getExchangePrice, postExchange } from '../../../api/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import Loading from '../../../assets/lottie/Loading';
import { toast } from 'react-toastify';
import PasswordModal from '../../../@modal/PasswordModal';
import { useRecoilValue } from 'recoil';
import { textData } from '../../../atom/language';
import { ExchangePriceResponse } from '../../../@types/responseTypes';
import AlertIcon from '../../../assets/icon/AlertIcon';

type RequestStateTypes = {
    requestPrice: number,
    selectedCoinValue: CoinTypes,
    receiveCoinValue: CoinTypes,
    receiveAddress: string,
    refoundAddress: string
}

export default function Confirmation() {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const location = useLocation();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [passwordCheck, setPasswordCheck] = useState<string>('');
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const { Confirmation: text } = useRecoilValue(textData);
    const requestState: RequestStateTypes = location.state;

    if (!requestState) {
        navigate('/', { replace: true });
    }

    const { data: exchangePrice, isLoading } = useQuery<ExchangePriceResponse>({
        queryKey: ['anticipated_price', `${requestState?.requestPrice}`, `${requestState?.receiveCoinValue?.symbol}`],
        queryFn: () => getExchangePrice(requestState?.selectedCoinValue.symbol, `${requestState?.requestPrice}`, requestState?.receiveCoinValue?.symbol),
        staleTime: 0,
        enabled: requestState?.receiveAddress.length > 0
    })

    const { mutate, isPending } = useMutation({
        mutationKey: ['post_exchange'],
        mutationFn: postExchange,
        onSuccess(data) {
            console.log(data);
            toast('Exchange created successfully', { type: 'success' })
            console.log(requestState, 'requestState');
            navigate(
                `/exchange/checking`,
                {
                    state: { id: data.id, password },
                    replace: true
                }
            );
        },
        onError(error) {
            console.log(error, 'error');
            toast('Failed to create exchange', { type: 'error' })
        }
    })

    const onSubmit = () => {
        if (!termsChecked) {
            toast(text[1](), { type: 'error' });
            return;
        }

        setModalOpen(true);
    }

    const onPasswordModalSubmit = () => {

        if (password !== passwordCheck) {
            toast(text[2](), { type: 'error' });
            setPassword('');
            setPasswordCheck('');
            return;
        }

        if (password.length >= 4) {
            setModalOpen(false);
            mutate({
                from_asset_symbol: requestState.selectedCoinValue.symbol,
                from_amount: parseFloat(String(requestState.requestPrice)),
                to_amount: exchangePrice?.estimate_amount as number,
                to_asset_symbol: requestState.receiveCoinValue.symbol,
                receipient_address: requestState.receiveAddress,
                refund_address: requestState.refoundAddress,
                password
            });
        } else {
            toast(text[3](), { type: 'error' });
            setPassword('');
        }
    }

    if (!requestState) return null;

    return (
        <Flex minW={'100%'} minH={'100dvh'} flexDirection={'column'}>
            <PasswordModal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                value={password}
                setValue={setPassword}
                onSubmit={onPasswordModalSubmit}
                valueCheck={passwordCheck}
                setValueCheck={setPasswordCheck}
            />
            <Header />
            <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
                <Flex w={isWeb ? 1200 : 'full'} backgroundColor={COLORS.white} h={isWeb ? 'fit-content' : '530px'} borderRadius={15} boxShadow={'xl'} paddingX={5} flexDirection={'column'} py={5}>
                    <Box mt={5} borderBottomWidth={2} borderColor={COLORS.light_gray}>
                        {isWeb && <StatusSection />}
                    </Box>
                    <Flex py={5} alignItems={'center'} borderBottomWidth={2} borderColor={COLORS.light_gray}>
                        <Box mr={isWeb ? 10 : 5}>
                            <Text mb={isWeb ? 5 : 1} color={COLORS.gray} fontSize={isWeb ? 20 : 12}>
                                {text[4]()}
                            </Text>
                            <Text fontWeight={'bold'} fontSize={isWeb ? 25 : 17}>
                                {requestState?.requestPrice} {requestState?.selectedCoinValue.symbol}
                            </Text>
                        </Box>
                        <ArrowLineIcon direction='right' size={isWeb ? 40 : 20} color={COLORS.gray} />
                        <Box ml={isWeb ? 10 : 5} >
                            <Text mb={isWeb ? 5 : 1} color={COLORS.gray} fontSize={isWeb ? 20 : 12}>
                                {text[5]()}
                            </Text>
                            {
                                isLoading
                                    ? <Loading size={120} />
                                    :
                                    <Text fontWeight={'bold'} fontSize={isWeb ? 25 : 17}>
                                        ≈{exchangePrice?.estimate_amount} {requestState?.receiveCoinValue.symbol}
                                    </Text>
                            }
                        </Box>
                    </Flex>
                    <Flex py={5} flexDirection={'column'} justifyContent={'center'} borderBottomWidth={2} borderColor={COLORS.light_gray}>
                        <Text color={COLORS.gray} fontSize={isWeb ? 20 : 12}>
                            {text[6]()}
                        </Text>
                        <Flex mt={3} alignItems={'center'}>
                            <Text color={COLORS.black} fontWeight={'bold'} fontSize={isWeb ? 20 : 10}>
                                {requestState.receiveAddress}
                            </Text>
                            {/* <Button height={isWeb ? '40px' : '25px'} onClick={() => window.open(`https://blockchair.com/ethereum/address/${requestState.receiveAddress}`)} ml={isWeb ? 5 : 2} fontSize={isWeb ? 12 : 10} colorScheme='yellow'>
                                {text[7]()}
                            </Button> */}
                        </Flex>
                    </Flex>
                    <Flex py={5} alignItems={'center'} borderBottomWidth={2} borderColor={COLORS.light_gray}>
                        <AlertIcon color={`#4AC97E `} />
                        <Text ml={3} fontSize={isWeb ? 14 : 11}>
                            {text[11]()}
                        </Text>
                    </Flex>
                    <Flex w={'full'} mt={5} flexDirection={isWeb ? 'row' : 'column'} justifyContent={isWeb ? 'space-between' : 'flex-start'} alignItems={isWeb ? 'center' : 'flex-start'}>
                        <Flex alignItems={'center'} >
                            <Checkbox size='lg' colorScheme='green' mr={3} checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)}>
                                <Text fontSize={isWeb ? 13 : 12}>
                                    {text[8]()}
                                </Text>
                            </Checkbox>
                        </Flex>
                        <Flex flexDirection={isWeb ? 'row' : 'column'} flex={isWeb ? 1 : 0} w={'full'} justifyContent={isWeb ? 'flex-end' : 'center'} alignItems={'center'}>
                            <Button w={isWeb ? 100 : 'full'} mt={isWeb ? 0 : 5} fontSize={isWeb ? 17 : 13} onClick={() => navigate(-1)}>
                                {text[9]()}
                            </Button>
                            <Button
                                w={isWeb ? 100 : 'full'}
                                ml={isWeb ? 5 : 0}
                                mt={isWeb ? 0 : 5}
                                colorScheme='whatsapp'
                                fontSize={isWeb ? 17 : 13}
                                isLoading={isPending}
                                onClick={onSubmit}
                            >
                                {text[10]()}
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
