import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { useLayoutEffect, useState } from 'react'
import { COLORS } from '../../assets/colors'
import MenuIcon from '../../assets/icon/MenuIcon'
import { motion } from 'framer-motion';
import CloseIcon from '../../assets/icon/CloseIcon';
import LanguageButton from './LanguageButton';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { textData } from '../../atom/language';

export default function MobileHeader() {
    const [position, setPosition] = useState<number>(window.pageYOffset);
    const [visible, setVisible] = useState<boolean>(true);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const { Header: text } = useRecoilValue(textData);


    const onNavigate = (path: string) => {
        navigate(path);
        setMenuOpen(false);
    }

    useLayoutEffect(() => {
        const handleScroll = () => {
            const moving = window.pageYOffset;
            setVisible(position > moving);
            setPosition(moving);
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [position]);

    return (
        <>
            <Flex w={'full'} background={position !== 0 ? COLORS.background_color : 'transparent'} top={0} zIndex={99} transition={'all .3s ease-in-out'} px={5} py={6} alignItems={'center'} position={'fixed'} justifyContent={'space-between'}>
                <Box onClick={() => onNavigate('/')} >
                    <img src={require('../../assets/icon/header_logo.png')} width={100} />
                </Box>
                <Button backgroundColor={'transparent'} background={'transparent'} _hover={{ background: 'transparent' }} p={0} onClick={() => {
                    setMenuOpen(true)
                }}>
                    <MenuIcon color={COLORS.white} />
                </Button>
            </Flex>
            {menuOpen &&
                <motion.div
                    initial={{ opacity: 0, width: '100%', height: '100%', position: 'fixed', background: "rgba(0,0,0,0.3)", zIndex: 9999999 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <motion.div
                        initial={{ width: 0, height: 0, borderRadius: '50%', right: 30, top: 20, display: 'flex', flexDirection: 'column' }}
                        animate={{ width: '100%', height: '100%', backgroundColor: COLORS.black, position: 'absolute', zIndex: 9999999, borderRadius: 0, right: 0, top: 0, padding: 15 }}
                        transition={{ duration: .4 }}
                    >
                        <Flex justifyContent={'space-between'}>
                            <Box onClick={() => onNavigate('/')}>
                                <img src={require('../../assets/icon/logo.png')} width={80} />
                            </Box>
                            <Button onClick={() => setMenuOpen(false)} backgroundColor={'transparent'} background={'transparent'} _hover={{ background: 'transparent' }} p={0}>
                                <CloseIcon color={COLORS.white} />
                            </Button>
                        </Flex>
                        <Box w={'full'} mt={5}>
                            <LanguageButton />
                        </Box>
                        <Flex flexDirection={'column'} mt={5} flex={1}>
                            <Box mt={10} onClick={() => onNavigate('/about')}>
                                <Text fontSize={17} color={COLORS.white}>{text[1]()}</Text>
                            </Box>
                            <Box mt={10} onClick={() => onNavigate('/my_exchange')}>
                                <Text fontSize={17} color={COLORS.white}>{text[2]()}</Text>
                            </Box>
                            <Box mt={10} onClick={() => onNavigate('/support')}>
                                <Text fontSize={17} color={COLORS.white}>{text[3]()}</Text>
                            </Box>
                        </Flex>
                        {/* <Button colorScheme='blue'>
                            Chat
                        </Button> */}
                    </motion.div>
                </motion.div>
            }
        </>
    )
}
