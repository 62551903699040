import { StatusType } from "../@types/statusTypes";

export const statusMenuEn: StatusType[] = [
    {
        status: '/exchange/new',
        title: 'Send to'
    },
    {
        status: '/exchange/confirmation',
        title: 'Confirmation'
    },
    {
        status: '/exchange/checking/:id',
        title: 'Exchange'
    },
    {
        status: '/exchange/finish/:id',
        title: 'Finish'
    },
]

export const statusMenuKo: StatusType[] = [
    {
        status: '/exchange/new',
        title: '보내는 주소 입력'
    },
    {
        status: '/exchange/confirmation',
        title: '교환 확인'
    },
    {
        status: '/exchange/checking/:id',
        title: '교환'
    },
    {
        status: '/exchange/finish/:id',
        title: '완료'
    },
]

export const statusMenuJp: StatusType[] = [
    {
        status: '/exchange/new',
        title: '送る'
    },
    {
        status: '/exchange/confirmation',
        title: '確認'
    },
    {
        status: '/exchange/checking/:id',
        title: '交換'
    },
    {
        status: '/exchange/finish/:id',
        title: '完了'
    },
]