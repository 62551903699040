import { Box, Button, Flex, Input, Text, useMediaQuery } from '@chakra-ui/react'
import React, { SetStateAction, useState } from 'react'
import Header from '../_components/Header'
import { COLORS } from '../../assets/colors'
import { mobileCheck } from '../../@utils/utils'
import { useMutation } from '@tanstack/react-query'
import { userExchangeCheck } from '../../api/request'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { textData } from '../../atom/language'

type ExchangeValueTypes = {
    exchangeId: string;
    password: string;
}

export default function MyExchange() {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const [values, setValues] = useState<ExchangeValueTypes>({
        exchangeId: '',
        password: ''
    })
    const { MyExchange: text } = useRecoilValue(textData);
    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value.trim()
        })
    }

    const onSubmit = () => {
        if (!values.exchangeId || !values.password) return toast(text[1](), { type: 'error' });
        navigate('/exchange/checking', { state: { id: values.exchangeId, password: values.password } });
    }

    return (
        <Flex minW={'100%'} minH={'100dvh'} flexDirection={'column'}>
            <Header />
            <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
                <Flex w={isWeb ? 600 : 'full'} backgroundColor={COLORS.white} h={isWeb ? 'fit-content' : '330px'} borderRadius={15} boxShadow={'xl'} paddingX={5} flexDirection={'column'} py={5}>
                    <Flex flexDirection={'column'} h={isWeb ? 300 : 'inherit'} alignItems={'center'} justifyContent={'center'}>
                        <Box w={'full'}>
                            <Text
                                mb={1}
                                fontSize={isWeb ? 17 : 14}
                                fontWeight={'bold'}
                            >
                                {text[2]()}
                            </Text>
                            <Input
                                placeholder={text[3]()}
                                _placeholder={{ fontSize: isWeb ? 15 : 13 }}
                                name='exchangeId'
                                onChange={handleChange}
                                value={values.exchangeId}
                            />
                        </Box>
                        <Box w={'full'} mt={5}>
                            <Text
                                mb={1}
                                fontSize={isWeb ? 17 : 14}
                                fontWeight={'bold'}
                            >
                                {text[4]()}
                            </Text>
                            <Input
                                placeholder={text[5]()}
                                _placeholder={{ fontSize: isWeb ? 15 : 13 }}
                                name='password'
                                onChange={handleChange}
                                type='password'
                                value={values.password}
                            />
                        </Box>
                    </Flex>
                    <Button colorScheme='whatsapp' onClick={onSubmit}>
                        <Text fontSize={isWeb ? 16 : 13}>
                            {text[6]()}
                        </Text>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}
