import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { mobileCheck } from '../../@utils/utils';
import AboutSwabi from './About/WhyUseSection';
import UsingDescriptionSection from './About/UsingDescriptionSection';
import useMoveScroll from '../../hooks/useMoveScroll';

export default function AboutSection() {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const { element } = useMoveScroll();

    return (
        <Flex w={'full'} flexDirection={'column'} ref={element}>
            {/* <AboutSwabi /> */}
            <UsingDescriptionSection />
        </Flex>
    )
}
