import React from 'react'
import { CoinTypes } from '../../../@types/coinTypes'
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { COLORS } from '../../../assets/colors'
import useSelectedCoin from '../../../hooks/useSelectedCoin'
import { getSymbolUrl, mobileCheck } from '../../../@utils/utils'

interface Props {
    coin: CoinTypes,
    onSelect: (coin: CoinTypes) => void,
    type: 'send' | 'receive'
}

export default function CoinList({ coin, onSelect, type }: Props) {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    return (
        <Flex
            w={'full'}
            minH={isWeb ? '70px' : '45px'}
            px={5}
            borderBottomWidth={1}
            borderTopColor={COLORS.light_gray}
            alignItems={'center'}
            transition={'all .5s'}
            _hover={{ backgroundColor: COLORS.light_gray }}
            onClick={() => {
                onSelect(coin);
            }}
            cursor={'pointer'}
        >
            <Box mr={5}>
                <Box borderRadius={'50px'} overflow={'hidden'}>
                    <img crossOrigin="anonymous" src={getSymbolUrl(coin.logo_url)} alt={coin.name} width={isWeb ? 30 : 20} height={isWeb ? 30 : 20} />
                </Box>
            </Box>
            <Flex h={'full'} flexDirection={'column'} >
                <Text fontSize={isWeb ? 15 : 13} fontWeight={'bold'}>
                    {coin.symbol}
                </Text>
                <Text color={COLORS.gray} fontSize={isWeb ? 13 : 10}>
                    {coin.name}
                </Text>
            </Flex>
        </Flex>
    )
}
