import React from 'react'
import { COLORS } from '../colors'
import { IconProps } from '../assetTypes'

export default function Telegram({ color = COLORS.white, size = 32 }: IconProps) {
    return (
        <svg width={`${size}`} height={`${size}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_107_337)">
                <path d="M15.9253 1.3038e-05C11.6948 0.0197556 7.64431 1.71418 4.65986 4.71262C1.67541 7.71107 -4.60654e-05 11.7695 9.49913e-10 16C9.49921e-10 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68689 27.3137 4.68631C24.3131 1.68572 20.2435 1.3038e-05 16 1.3038e-05C15.9751 -4.50361e-05 15.9502 -4.50361e-05 15.9253 1.3038e-05ZM22.5413 9.63201C22.6747 9.62935 22.9693 9.66268 23.1613 9.81868C23.2889 9.92948 23.3703 10.0841 23.3893 10.252C23.4107 10.376 23.4373 10.66 23.416 10.8813C23.176 13.412 22.1333 19.5507 21.6027 22.384C21.3787 23.584 20.9373 23.9853 20.5093 24.024C19.5813 24.1107 18.876 23.4107 17.976 22.8213C16.568 21.8973 15.772 21.3227 14.4053 20.4213C12.8253 19.3813 13.8493 18.808 14.7493 17.8747C14.9853 17.6293 19.0787 13.9053 19.1587 13.568C19.168 13.5253 19.1773 13.368 19.084 13.2853C18.9907 13.2027 18.852 13.2307 18.752 13.2533C18.6107 13.2853 16.3613 14.7733 12.004 17.7133C11.364 18.1533 10.7867 18.3667 10.268 18.3533C9.69733 18.3427 8.59867 18.032 7.78133 17.7667C6.77867 17.44 5.98267 17.268 6.052 16.7147C6.088 16.4267 6.48533 16.132 7.24267 15.8307C11.9067 13.7987 15.016 12.4587 16.5733 11.812C21.016 9.96401 21.94 9.64268 22.5413 9.63201Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_107_337">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
