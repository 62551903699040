import { createContext, useState } from "react"

interface Props {
    children: React.ReactNode
}

export const TabContext = createContext({
    tab: 'exchange',
    setTab: (value: 'exchange' | 'buy'): void => { }
})

export default function TabProvider({ children }: Props) {

    const [tab, setTab] = useState<'exchange' | 'buy'>('exchange');

    return (
        <TabContext.Provider value={{ tab, setTab }}>
            {children}
        </TabContext.Provider>
    )
}
