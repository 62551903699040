import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { faker } from '@faker-js/faker';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../assets/colors';
import { mobileCheck } from '../../@utils/utils';
import LanguageButton from './LanguageButton';
import MobileHeader from './MobileHeader';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { language, textData } from '../../atom/language';
import { Cookies } from 'react-cookie';
import useMoveScroll from '../../hooks/useMoveScroll';

export default function Header() {

    const [position, setPosition] = useState<number>(window.pageYOffset);
    const [visible, setVisible] = useState<boolean>(true);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const navigate = useNavigate()
    const { Header: text } = useRecoilValue(textData);
    const { moveScroll } = useMoveScroll();

    useLayoutEffect(() => {
        const handleScroll = () => {
            const moving = window.pageYOffset;
            setVisible(position > moving);
            setPosition(moving);
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [position]);

    if (!isWeb) {
        return (
            <MobileHeader />
        )
    }

    const moveAbout = () => {
        navigate('/');
        setTimeout(() => {
            console.log('hello')
            moveScroll();
        }, 500)
    }

    return (
        <Flex width={'100%'} background={position !== 0 ? COLORS.background_color : 'transparent'} h={30} px={10} py={10} justifyContent={'space-between'} alignItems={'center'} left={0} top={visible ? 0 : -150} position={'fixed'} zIndex={99999} transition={'all .3s ease-in-out'}>
            <Flex alignItems={'center'}>
                <Box cursor={'pointer'} onClick={() => navigate('/')} w={'fit-content'}>
                    <img src={require('../../assets/icon/header_logo.png')} width={120} />
                </Box>
            </Flex>
            <Flex flex={1} alignItems={'flex-end'} justifyContent={'center'}>
                <Box cursor={'pointer'} onClick={() => navigate('/about')}>
                    <Text color={COLORS.white} fontWeight={'bold'} fontSize={18}>
                        {text[1]()}
                    </Text>
                </Box>
                <Box cursor={'pointer'} onClick={() => navigate('/my_exchange')} mx={10}>
                    <Text color={COLORS.white} fontWeight={'bold'} fontSize={18}>
                        {text[2]()}
                    </Text>
                </Box>
                <Box cursor={'pointer'} onClick={() => navigate('/support')}>
                    <Text color={COLORS.white} fontWeight={'bold'} fontSize={18}>
                        {text[3]()}
                    </Text>
                </Box>
            </Flex>
            <Flex alignItems={'center'}>
                <LanguageButton />
            </Flex>
        </Flex>
    )
}
