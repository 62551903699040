import { Button, Center, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { mobileCheck } from '../../../@utils/utils';
import { COLORS } from '../../../assets/colors';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ReviewBox from './ReviewBox';
import { useQuery } from '@tanstack/react-query';
import { getReviewData } from '../../../api/request';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import CreateReviewModal from '../../../@modal/CreateReviewModal';
import { useRecoilValue } from 'recoil';
import { textData } from '../../../atom/language';

type ReviewResponseType = {
    id: string,
    exchange_id: string,
    nickname: string,
    score: number,
    content: string,
    email_address: string
}

export default function ReviewSection() {
    const { data: reviewData, isLoading, isError } = useQuery<ReviewResponseType[]>({
        queryKey: ['getReviews'],
        queryFn: getReviewData,
        gcTime: Infinity
    })
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const [reviewModal, setReviewModal] = useState<boolean>(false);
    const { ReviewSection: text } = useRecoilValue(textData);

    useEffect(() => { console.log(reviewData) }, [reviewData])

    if (isError) {
        return (
            <></>
        )
    }

    return (
        <>
            <CreateReviewModal isOpen={reviewModal} setIsOpen={setReviewModal} />
            <Flex w={'full'} h={500} py={10} px={isWeb ? 20 : 10} backgroundColor={COLORS.white} flexDirection={'column'}>
                <Text fontSize={20} fontWeight={'bold'}>
                    {text[1]()}
                </Text>
                <Swiper
                    spaceBetween={isWeb ? 10 : 5}
                    slidesPerView={isWeb ? 4 : 1}
                    style={{ width: '100%', marginTop: 20, height: '100%', alignItems: 'center', paddingBottom: 10, paddingTop: 10, paddingInline: isWeb ? 0 : 5 }}
                    loop={true}
                    pagination={{
                        dynamicBullets: true,
                        clickable: true
                    }}
                    modules={[Pagination]}
                >
                    {
                        reviewData?.map(value => (
                            <SwiperSlide key={value.id}>
                                <ReviewBox {...value} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Flex>
            <Center h={300} backgroundColor={COLORS.black} flexDirection={'column'}>
                <Text fontWeight={'bold'} fontSize={isWeb ? 20 : 14} color={COLORS.white}>
                    {text[2]()}
                </Text>
                <Button mt={5} colorScheme='whatsapp' fontSize={14} onClick={() => setReviewModal(true)}>
                    {text[3]()}
                </Button>
            </Center>
        </>
    )
}
