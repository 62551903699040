import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react'
import { useLocation } from 'react-router-dom'
import { COLORS } from '../../../assets/colors';
import { statusMenuEn, statusMenuJp, statusMenuKo } from '../../../@utils/menu';
import StatusList from './StatusList';
import { useRecoilValue } from 'recoil';
import { language } from '../../../atom/language';
import { mobileCheck } from '../../../@utils/utils';

interface Props {
    status?: string;
}

export default function StatusSection({ status }: Props) {

    const lang = useRecoilValue(language);

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    const statusMenu = lang === 'en' ? statusMenuEn : lang === 'ko' ? statusMenuKo : statusMenuJp;

    return (
        <Flex w={'full'} h={isWeb ? '40px' : '30px'} mb={isWeb ? 5 : 2} alignItems={'center'} justifyContent={'space-between'}>
            {
                statusMenu?.map((menu, index) => (
                    <StatusList status={menu.status} title={menu.title} key={index} finish={status === 'Finish'} />
                ))
            }
        </Flex>
    )
}
