import axios from "axios"
import { CoinTypes } from "../@types/coinTypes";
import { QueryFunction } from "@tanstack/react-query";
import { ExchangeFeeResponse } from "../@types/responseTypes";

type RequestStateTypes = {
    from_asset_symbol: string,
    from_amount: number,
    to_asset_symbol: string,
    to_amount: number,
    receipient_address: string,
    refund_address?: string,
    password: string
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

const devInstance = axios.create({
    baseURL: process.env.REACT_APP_DEV_API_URL,
})

export const getCoinList = async () => {
    const { data } = await devInstance.get('/exchange/assets');

    return data;
}

export const getExchangePrice = async (from_symbol: string, amount: string, to_symbol: string) => {
    const { data } = await devInstance.get(`/exchange/exchange_rate?from_asset_symbol=${from_symbol}&amount=${amount}&to_asset_symbol=${to_symbol}`);

    return data;
}

export const postExchange = async (body: RequestStateTypes) => {
    console.log(body, 'body');
    const { data } = await devInstance.post(`/exchange/create_exchange`, body);

    return data;
}

export const userExchangeCheck = async ({ id, password }: { id: string, password: string }) => {
    const { data } = await devInstance.post(`/exchange/exchange_state`, { exchange_id: id, password });

    return data;
}

export const postSupport = async ({ exchangeId, email, content }: { email: string, content: string, exchangeId: string }) => {
    const { data } = await devInstance.post(`/support/enquiry`, {
        email_address: email,
        exchange_id: exchangeId,
        content
    });

    return data;
}

export const getSearchCoin = async (symbol: string) => {
    const { data } = await devInstance.get(`/exchange/assets?symbol=${symbol}`);

    return data;
}

export const getAddressVerify = async ({
    address,
    network_name
}: { address: string, network_name: string }) => {
    console.log(213321)
    const { data } = await devInstance.get(`/exchange/verify_onchain_address?address=${address}&network_name=${network_name}`);

    return data;
}

export const getExchangeFeeLimit: QueryFunction<ExchangeFeeResponse, [_1: string, _2: string]> = async ({ queryKey }) => {
    const [_, symbol] = queryKey;

    const { data } = await devInstance.get(`/exchange/exchange_fee_limit?symbol=${symbol}`);

    return data;
}

export const getReviewData = async () => {
    const { data } = await devInstance.get(`/review/exchange_review?limit=20`);

    return data;
}

export const createReview = async ({ email, tradeId, name, review, ratingValue }: { email: string, tradeId: any, name: string, review: string, ratingValue: number }) => {
    const { data } = await devInstance.post(`/review/exchange_review`, {
        email_address: email,
        exchange_id: tradeId,
        nickname: name,
        content: review,
        score: ratingValue
    });

    return data;
}