import { atom } from "recoil";
import { COIN_DUMMY_DATA } from "../mocks/dummy";
import { CoinTypes } from "../@types/coinTypes";

export const selectedCoinAtom = atom<CoinTypes>({
    key: 'selectedCoin',
    default: {
        name: '',
        symbol: '',
        logo_url: '',
        address: '',
        network: {
            name: '',
            chain_id: '',
            is_testnet: false,
        },
    }
})

export const receiveCoinAtom = atom<CoinTypes>({
    key: 'receiveCoin',
    default: {
        name: '',
        symbol: '',
        logo_url: '',
        address: '',
        network: {
            name: '',
            chain_id: '',
            is_testnet: false,
        }
    }
})