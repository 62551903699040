import { Button, Center, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { mobileCheck } from '../@utils/utils';
import { COLORS } from '../assets/colors';
import { useRecoilValue } from 'recoil';
import { textData } from '../atom/language';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CoinTypes } from '../@types/coinTypes';
import { getCoinList, getSearchCoin } from '../api/request';
import useSelectedCoin from '../hooks/useSelectedCoin';
import CoinList from '../pages/home/_components/CoinList';

interface ModalProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    type: 'send' | 'receive'
}

export default function MobileSelectedModal({ isOpen, setIsOpen, type }: ModalProps) {

    const onClose = () => setIsOpen(false);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const { PasswordModal: text } = useRecoilValue(textData);
    const [searchValue, setSearchValue] = useState<string>('');
    const { handleSelectedCoin, selectedCoinValue, receiveCoinValue } = useSelectedCoin();
    const [searchList, setSearchList] = useState<CoinTypes[] | []>([]);
    const queryClient = useQueryClient();
    const selectCoin = type === 'send' ? selectedCoinValue : receiveCoinValue;
    const queryClinet = useQueryClient();
    const { data } = useQuery<CoinTypes[] | []>({
        queryKey: ['coinList', searchValue],
        queryFn: () => getSearchCoin(searchValue),
        staleTime: 0,
        enabled: searchValue !== ''
    })
    const coinList = queryClient.getQueryData<CoinTypes[]>(['coinList']);


    useEffect(() => {
        if (coinList && searchValue.length === 0) {
            setSearchList(coinList);
        }

        if (searchValue.length > 0 && data) {
            setSearchList(data);
        }
    }, [searchValue, data])

    const onSelected = (coin: CoinTypes) => {
        handleSelectedCoin(coin, type);
        onClose();
    }

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>코인 선택</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={0} minHeight={300} overflowY={'scroll'}>
                    <Input
                        onChange={onSearch}
                        placeholder='Type a currency or ticket'
                    />
                    {
                        searchList.length === 0 && (
                            <Center w={'full'} h={200}>
                                <Text color={COLORS.gray} fontSize={20}>
                                    Not Found Coin
                                </Text>
                            </Center>
                        )
                    }
                    {
                        searchList?.filter(coin => {
                            // 보내는 영역일 때는 모든 코인을 보여주고 받는 영역일 때는 선택된 코인을 제외한 코인을 보여준다.
                            if (type === 'send') {
                                return coin;
                            } else {
                                return coin.name !== selectCoin.name
                            }
                        }).map((coin) => <CoinList coin={coin} onSelect={onSelected} type={type} key={coin.name} />)
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
