import { Box, Button, Center, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { mobileCheck } from '../@utils/utils';
import { COLORS } from '../assets/colors';
import { useRecoilValue } from 'recoil';
import { textData } from '../atom/language';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CoinTypes } from '../@types/coinTypes';
import { createReview, getCoinList, getSearchCoin } from '../api/request';
import useSelectedCoin from '../hooks/useSelectedCoin';
import CoinList from '../pages/home/_components/CoinList';
import StarIcon from '../assets/icon/StarIcon';
import { toast } from 'react-toastify';

interface ModalProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function CreateReviewModal({ isOpen, setIsOpen }: ModalProps) {
    const { CreateReviewModal: text } = useRecoilValue(textData);
    const { mutate, isPending } = useMutation({
        mutationKey: ['createReview'],
        mutationFn: createReview,
        onSuccess: (data) => {
            toast(text[1](), { type: 'success' });
            setIsOpen(false);
        },
        onError: () => toast(text[3](), { type: 'error' })
    })
    const onClose = () => setIsOpen(false);
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const [rating, setRating] = useState<number[]>([1, 1, 1, 1, 1]);
    const emailRef = useRef<HTMLInputElement>(null);
    const tradeIdRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const reviewRef = useRef<HTMLTextAreaElement>(null);

    const onReviewClick = (index: number) => {
        setRating([...Array(index + 1).fill(1), ...Array(5 - index - 1).fill(0)]);
    }

    const onSubmit = () => {
        const email = emailRef.current?.value;
        const tradeId = tradeIdRef.current?.value;
        const name = nameRef.current?.value;
        const review = reviewRef.current?.value;
        const ratingValue = rating.filter(value => value === 1).length;

        if (email && name && review && ratingValue) {
            mutate({
                email,
                tradeId: null,
                name,
                review,
                ratingValue
            })
        } else {
            toast(text[4](), { type: 'error' })
        }
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent minW={isWeb ? '40%' : '100%'} zIndex={9999999}>
                <ModalHeader>{text[5]()}</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={5} w={'100%'}>
                    <Box>
                        <Text mb={isWeb ? 3 : 1}>{text[6]()}</Text>
                        <Flex>
                            {
                                rating.map((_, index) => (
                                    <Button p={0} key={index} onClick={() => onReviewClick(index)} background={'transparent'} _hover={{ background: isWeb ? COLORS.light_gray : 'transparent' }}>
                                        <StarIcon color={rating[index] === 1 ? '#FFD400' : COLORS.gray} />
                                    </Button>
                                ))
                            }
                        </Flex>
                    </Box>
                    <Box mt={isWeb ? 5 : 3}>
                        <Text mb={isWeb ? 3 : 1}>{text[7]()}</Text>
                        <Input
                            placeholder={text[8]()}
                            ref={emailRef}
                            _placeholder={{ fontSize: 14 }}
                            fontSize={isWeb ? 13 : 12}
                        />
                    </Box>
                    {/* <Box mt={isWeb ? 5 : 3}>
                        <Text mb={isWeb ? 3 : 1}>{text[9]()}</Text>
                        <Input
                            placeholder={text[10]()}
                            ref={tradeIdRef}
                            _placeholder={{ fontSize: 14 }}
                            fontSize={isWeb ? 13 : 12}
                        />
                    </Box> */}
                    <Box mt={isWeb ? 5 : 3}>
                        <Text mb={isWeb ? 3 : 1}>{text[11]()}</Text>
                        <Input
                            placeholder={text[12]()}
                            ref={nameRef}
                            _placeholder={{ fontSize: 14 }}
                            fontSize={isWeb ? 13 : 12}
                        />
                    </Box>
                    <Box mt={isWeb ? 5 : 3}>
                        <Text mb={isWeb ? 3 : 1}>{text[13]()}</Text>
                        <Textarea
                            placeholder={text[14]()}
                            _placeholder={{ fontSize: 14 }}
                            ref={reviewRef}
                            fontSize={isWeb ? 13 : 12}
                            h={100}
                            resize={'none'}
                        />
                    </Box>
                    <Button
                        w={'full'}
                        my={5}
                        colorScheme={'whatsapp'}
                        fontSize={isWeb ? 14 : 12}
                        onClick={onSubmit}
                        isLoading={isPending}
                    >
                        {text[15]()}
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
