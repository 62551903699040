import { LanguageTypes } from "../@types/utilType";

export const ko: LanguageTypes = {
    ExchangeSection: {
        1: () => "당신을 위한 최고의 암호화폐 거래소!",
        2: () => "거래 시작"
    },
    Header: {
        1: () => "소개",
        2: () => "나의 거래",
        3: () => "지원"
    },
    ExchangeNew: {
        1: () => "거래",
        2: (symbol) => `${symbol} 주소를 입력하세요`,
        3: (symbol) => `${symbol} 지급 주소로 스마트 계약을 제공하지 마세요`,
        4: () => `환불 주소`,
        5: () => `환불 주소를 입력하세요 (선택)`,
        6: () => `다음`,
        7: () => `해당 지갑주소가 유효하지 않습니다`,
        8: () => `해당 환불 지갑주소가 존재하지 않습니다`,
    },
    Confirmation: {
        1: () => `이용 약관을 확인해주세요`,
        2: () => `비밀번호가 일치하지 않습니다`,
        3: () => `최소 4자리 이상의 비밀번호를 입력해주세요`,
        4: () => `보내기`,
        5: () => `받기`,
        6: () => `주소로`,
        7: () => `주소 확인 중`,
        8: () => `약관에 동의하였습니다`,
        9: () => `뒤로`,
        10: () => `다음`,
        11: () => `입금 완료 후 거래 시간은 대략 최대 40분 이상 소요됩니다`,
    },
    PasswordModal: {
        1: () => `거래 비밀번호 설정`,
        2: () => `비밀번호 입력`,
        3: () => `비밀번호 확인`,
        4: () => `비밀번호는 신청 후 진행 상황을 확인하는 데 사용됩니다`,
        5: () => `닫기`,
        6: () => `제출`,
    },
    Exchange: {
        1: () => `클립보드에 복사되었습니다`,
        2: () => `거래가 완료되었습니다`,
        3: () => `거래 ID: `,
        4: () => `복사`,
        5: () => `보내기:`,
        6: () => `주소로:`,
        7: () => `받기:`,
        8: () => `받는 사람의 주소:`,
        9: () => `지원`,
        10: () => `홈`,
        11: () => `거래 상태`,
        12: () => `해당 거래가 존재하지 않습니다`,
        13: () => `토큰이 만료되었습니다`
    },
    MyExchange: {
        1: () => `모든 필드를 채워주세요`,
        2: () => `거래 ID`,
        3: () => `거래 ID를 입력하세요`,
        4: () => `비밀번호`,
        5: () => `거래 비밀번호를 입력하세요`,
        6: () => `나의 거래 확인`,
    },
    Error: {
        1: () => `서버 점검 중`,
        2: () => `서버를 점검중입니다. 잠시만 기다려주세요`
    },
    Support: {
        1: () => `문의가 성공적으로 전송되었습니다`,
        2: () => `문의를 전송하지 못했습니다. 다시 시도해주세요`,
        3: () => `모든 필드를 채워주세요`,
        4: () => `문의 하실 내용을 적어주세요`,
        5: () => `보내기`,
        6: () => `지원`,
        7: () => `이메일을 입력하세요`,
        8: () => `거래 아이디를 입력하세요`,
    },
    About: {
        1: () => `신뢰`,
        2: () => `Swabee는 블록체인 상에서 모든 거래가 이루어지기 때문에 최상의 안정성을 보장합니다. 안전한 거래를 경험해보세요`,
        3: () => `신속한 거래`,
        4: () => `Swabee는 내부적으로 사용자에게 가장 최적화된 환율과 거래 루트를 검색하여 최대한 빠르고 효율적인 거래가 이루어질 수 있도록 사용자의 요구에 맞춰 빠르고 효율적인 거래를 제공합니다`,
        5: () => `빠른 피드백`,
        6: () => `Swabee는 사용자의 의견을 존중하며, 빠른 피드백을 제공하며, 끊임 없는 업데이트를 통해서 사용자의 편의성을 중시한 서비스를 제공합니다.`,
        7: () => `안전한 서비스`,
        8: () => `Swabee는 사용자의 안전을 위해 개인정보를 저장하지 않으며, 익명성을 보장합니다`,
        9: () => `끊임없는 업데이트`,
        10: () => `Swabee는 사용자의 편의를 위해 끊임없이 업데이트를 진행합니다`,
        11: () => `소개`,
        12: () => `소개글`,
        13: () => `Swabee는 사용자의 안전성을 우선으로 고려하여 만든 암호화폐 거래 플랫폼입니다. 우리는 사용자에게 번거로움 없이 일상적인 필요를 충족시킬 수 있는 최상의 서비스를 자랑스럽게 제공합니다. 개인정보를 존중하며, 등록이나 제한 없이 운영되는 Swabee는 사용자가 암호화폐를 손쉽게 교환할 수 있도록 합니다.`
    },
    UsingDescriptionSection: {
        1: () => `단계`,
        2: () => `거래할 화폐 선택`,
        3: () => `교환하려는 토큰을 선택하고 예상 결제 금액을 확인합니다`,
        4: () => `지갑 주소 입력`,
        5: () => `송금할 지갑 주소를 입력하세요`,
        6: () => `입금`,
        7: () => `안내받은 지갑 주소로 금액을 송금해 주세요`,
        8: () => `지급 확인`,
        9: () => `금액이 안전하게 입금되었는지 확인하세요`,
        10: () => `리뷰 작성`,
        11: () => `리뷰를 작성하고 다음 사용자에게 만족스러운 거래를 알려주세요`,
        12: () => `Swabee 사용법`,
    },
    ReviewSection: {
        1: () => `유저의 리뷰`,
        2: () => `5점 리뷰를 작성하시면 추첨을 통해 EML을 드립니다`,
        3: () => `작성하기`,
    },
    CreateReviewModal: {
        1: () => `리뷰가 작성되었습니다`,
        2: () => `리뷰 작성에 실패했습니다`,
        3: () => `리뷰 작성에 실패했습니다`,
        4: () => `모든 항목을 입력해주세요`,
        5: () => `리뷰 작성`,
        6: () => `별점`,
        7: () => `이메일`,
        8: () => `이메일을 입력해주세요`,
        9: () => `거래 ID`,
        10: () => `거래 ID를 입력해주세요`,
        11: () => `이름`,
        12: () => `이름을 입력해주세요`,
        13: () => `리뷰`,
        14: () => `리뷰를 작성해주세요`,
        15: () => `작성하기`,
    }
}