import { Button, Center, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React, { createContext, useEffect, useState } from 'react'
import { COLORS } from '../../../assets/colors'
import Tab from './Tab'
import TabProvider from '../../../provider/TabProvider'
import ExchangeInputSection from './ExchangeInputSection'
import { FONT } from '../../../assets/fonts'
import { mobileCheck } from '../../../@utils/utils'
import { useNavigate } from 'react-router-dom'
import useSelectedCoin from '../../../hooks/useSelectedCoin'
import { useRecoilValue } from 'recoil'
import { language, textData } from '../../../atom/language'
import { toast } from 'react-toastify'
import { useQueries, useQueryClient } from '@tanstack/react-query'
import { getExchangeFeeLimit, getExchangePrice } from '../../../api/request'
import { ExchangePriceResponse } from '../../../@types/responseTypes'

export default function ExchangeSection() {
    const { selectedCoinValue, receiveCoinValue } = useSelectedCoin();
    const queryClient = useQueryClient();
    const [
        sendFee,
        receiveFee,
    ] = useQueries({
        queries: [
            {
                queryKey: ['exchange_fee_send', selectedCoinValue.symbol],
                queryFn: getExchangeFeeLimit,
                enabled: selectedCoinValue.symbol !== '',
            },
            {
                queryKey: ['exchange_fee_receive', receiveCoinValue.symbol],
                queryFn: () => getExchangePrice(selectedCoinValue.symbol, String(requestPrice), receiveCoinValue.symbol),
                enabled: receiveCoinValue.symbol !== ''
            }
        ],
    })
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const [requestPrice, setRequestPrice] = useState<number>(0.1);
    const lang = useRecoilValue(language);
    const { ExchangeSection: text } = useRecoilValue(textData);
    const navigate = useNavigate();

    const { data: sendFeeData, isLoading: sendFeeLoading, isSuccess } = sendFee;
    const { data: receiveFeeData, isLoading: receiveFeeLoading } = receiveFee;


    useEffect(() => {
        if (isSuccess) {
            console.log(sendFeeData, 'sendFeeData')
            setRequestPrice(sendFeeData!.deposit_min_amount);
        }
    }, [isSuccess])

    useEffect(() => {
        console.log(receiveFee.isLoading, 'receiveFeeData')
    }, [receiveFee.isLoading])

    const onClickNavigate = () => {
        if (requestPrice === 0 || requestPrice === undefined || !requestPrice) return toast('Please enter the amount', { type: 'error' })
        const anticipated_price = queryClient.getQueryData<ExchangePriceResponse>(['anticipated_price', `${requestPrice}`, `${receiveCoinValue?.symbol}`]);

        if (anticipated_price) {
            if (requestPrice < anticipated_price!.deposit_min_amount) {
                if (lang === 'ko') {
                    return toast(`최소 입금액은 ${anticipated_price!.deposit_min_amount} ${selectedCoinValue.symbol} 입니다`, { type: 'error' })
                } else if (lang === 'en') {
                    return toast(`Minimum amount is ${anticipated_price!.deposit_min_amount} ${selectedCoinValue.symbol}`, { type: 'error' })
                } else {
                    return toast(`最小入金額は${anticipated_price!.deposit_min_amount} ${selectedCoinValue.symbol}です`, { type: 'error' })
                }
            }

            if (anticipated_price) {
                if (anticipated_price?.estimate_amount < anticipated_price!.withdrawal_min_amount) {
                    if (lang === 'ko') {
                        return toast(`최소 출금액은 ${anticipated_price!.withdrawal_min_amount} ${receiveCoinValue.symbol} 입니다`, { type: 'error' })
                    } else if (lang === 'en') {
                        return toast(`Minimum withdrawal amount is ${anticipated_price!.withdrawal_min_amount} ${receiveCoinValue.symbol}`, { type: 'error' })
                    } else {
                        return toast(`最小出金額は${anticipated_price!.withdrawal_min_amount} ${receiveCoinValue.symbol}です`, { type: 'error' })
                    }
                }
            }
        } else {
            return;
        }


        navigate('/exchange/new', {
            state: {
                requestPrice,
                selectedCoinValue,
                receiveCoinValue
            },
        });
    }

    return (
        <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
            <Center w={isWeb ? 1000 : 'full'} borderRadius={15} mb={10}>
                <Text color={COLORS.white} fontFamily={FONT.TTHakgyoansimSamulhamR} fontWeight={'bold'} fontSize={isWeb ? 40 : 18}>
                    {text[1]()}
                </Text>
            </Center>
            <Flex w={isWeb ? 1000 : 'full'} h={isWeb ? 350 : 300} backgroundColor={COLORS.white} borderRadius={15} boxShadow={'xl'} paddingX={5} flexDirection={'column'}>
                <TabProvider>
                    <Tab />
                    <ExchangeInputSection requestPrice={requestPrice} setRequestPrice={setRequestPrice} />
                    <Center w={'full'} paddingY={5}>
                        <Button onClick={onClickNavigate} colorScheme='whatsapp' padding={0} w={'full'} borderRadius={5} h={isWeb ? 50 : '40px'}>
                            <Text color={COLORS.white} fontWeight={'bold'} fontFamily={FONT.TTHakgyoansimSamulhamR} fontSize={isWeb ? 17 : 13}>
                                {text[2]()}
                            </Text>
                        </Button>
                    </Center>
                </TabProvider>
            </Flex>
        </Flex>
    )
}
