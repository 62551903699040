import { Box, Button, Center, Flex, Input, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../../assets/colors'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { CoinTypes } from '../../../@types/coinTypes';
import CoinList from './CoinList';
import useSelectedCoin from '../../../hooks/useSelectedCoin';
import { SearchTabTypes } from '../../../@types/utilType';
import { mobileCheck } from '../../../@utils/utils';
import CloseIcon from '../../../assets/icon/CloseIcon';
import { getCoinList, getSearchCoin } from '../../../api/request';

interface Props {
    type: 'send' | 'receive',
    setSearchTab: React.Dispatch<React.SetStateAction<SearchTabTypes>>
}

export default function SearchDrop({ type, setSearchTab }: Props) {

    const { receiveCoinValue, selectedCoinValue, handleSelectedCoin } = useSelectedCoin();
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const selectCoin = type === 'send' ? selectedCoinValue : receiveCoinValue;
    const queryClient = useQueryClient();
    const [searchValue, setSearchValue] = useState<string>('');
    const coinList = queryClient.getQueryData<CoinTypes[]>(['coinList']);
    const [searchList, setSearchList] = useState<CoinTypes[] | []>([]);
    const { data } = useQuery<CoinTypes[] | []>({
        queryKey: ['coinList', searchValue],
        queryFn: () => getSearchCoin(searchValue),
        staleTime: 0,
        enabled: searchValue !== ''
    })

    useEffect(() => {
        if (coinList && searchValue.length === 0) {
            setSearchList(coinList);
        }

        if (searchValue.length > 0 && data) {
            setSearchList(data);
        }
    }, [searchValue, data])



    const onSelected = (coin: CoinTypes) => {
        handleSelectedCoin(coin, type);
        setSearchTab('');
    }

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value.trim());
    }

    return (
        <Flex w={isWeb ? '45%' : '100%'} onClick={(e) => e.stopPropagation()} flexDirection={'column'} position={'relative'}>
            <Flex w={'100%'} h={isWeb ? '70px' : '50px'} backgroundColor={COLORS.light_gray} borderRadius={10} p={2} justifyContent={'space-between'} alignItems={'center'}>
                <Input
                    border={'none'}
                    _focusVisible={{ outline: 'none' }}
                    placeholder='Type a currency or ticket'
                    fontSize={isWeb ? 20 : 12}
                    onChange={onSearch}
                />
            </Flex>
            <VStack
                w={'100%'}
                minH={isWeb ? 300 : 150}
                maxH={isWeb ? 300 : 150}
                position={'absolute'}
                left={0}
                bottom={isWeb ? -300 : -150}
                zIndex={99}
                backgroundColor={COLORS.white}
                borderBottomRadius={10}
                overflowY={'scroll'}
            >
                {
                    searchList.length === 0 && (
                        <Center w={'full'} h={isWeb ? 300 : 150}>
                            <Text color={COLORS.gray} fontSize={20}>
                                Not Found Coin
                            </Text>
                        </Center>
                    )
                }
                {
                    !isWeb && (
                        <Flex w={'full'} py={3} justifyContent={'flex-end'}>
                            <Button p={0} w={15} size={'sm'} onClick={() => setSearchTab('')} >
                                <CloseIcon size={12} />
                            </Button>
                        </Flex>
                    )
                }
                {
                    searchList?.filter(coin => {
                        // 보내는 영역일 때는 모든 코인을 보여주고 받는 영역일 때는 선택된 코인을 제외한 코인을 보여준다.
                        if (type === 'send') {
                            return coin;
                        } else {
                            return coin.name !== selectCoin.name
                        }
                    }).map((coin) => <CoinList coin={coin} onSelect={onSelected} type={type} key={coin.name} />)
                }
            </VStack>
        </Flex>
    )
}
