import React from 'react'
import { IconProps } from '../assetTypes'
import { Box } from '@chakra-ui/react'
import { COLORS } from '../colors'

interface ArrowIconProps {
    direction?: 'up' | 'down' | 'left' | 'right'
}

export default function ArrowLineIcon({
    direction = 'right',
    color = COLORS.black,
    size = 20
}: IconProps & ArrowIconProps) {

    const arrowDirection = {
        up: 'rotate(90deg)',
        down: 'rotate(270deg)',
        left: 'rotate(0deg)',
        right: 'rotate(180deg)',
    }

    return (
        <Box transform={arrowDirection[direction]}>
            <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} fill={color} viewBox="0 0 256 256">
                <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
        </Box>
    )
}
