import { atom, selector } from 'recoil';
import { LanguageTypes } from '../@types/utilType';
import { ko } from '../language/ko';
import { en } from '../language/en';
import { jp } from '../language/jp';
export const language = atom<'en' | 'ko' | 'jp'>({
    key: 'language',
    default: 'en',
});

export const textData = selector<LanguageTypes>({
    key: "translatedText",
    get: ({ get }) => {
        const languageData = get(language);

        switch (languageData) {
            case "ko": return ko;
            case "en": return en;
            case "jp": return jp;
            default: return {}
        }
    }
})