import React from 'react'
import { IconProps } from '../assetTypes'
import { COLORS } from '../colors'

export default function Discord({ color = COLORS.white, size = 32 }: IconProps) {
    return (
        <svg width={`${size}`} height={`${size}`} viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg" >
            <g clipPath="url(#clip0_107_350)">
                <path d="M20.5333 4C20.2161 4.56812 19.9334 5.15483 19.6868 5.75696C17.2634 5.3737 14.7948 5.3737 12.3715 5.75696C12.1248 5.15483 11.8421 4.56812 11.5249 4C9.24215 4.39005 7.02298 5.08857 4.92836 6.0764C1.18275 11.4819 -0.515908 18.0444 0.136662 24.5883C2.57761 26.4233 5.31722 27.8229 8.23463 28.7252C8.89894 27.8477 9.49182 26.9183 10.0076 25.946C9.05573 25.595 8.14087 25.151 7.27629 24.6203C7.51342 24.4625 7.73773 24.2863 7.94713 24.0932C10.4683 25.3076 13.2307 25.9382 16.0291 25.9382C18.8275 25.9382 21.59 25.3076 24.1111 24.0932C24.3347 24.2849 24.5584 24.4606 24.782 24.6203C23.9127 25.1468 22.9987 25.5958 22.0507 25.962C22.545 26.9563 23.1221 27.9073 23.7757 28.805C26.6895 27.9058 29.4243 26.506 31.8577 24.6682C32.5259 18.1231 30.8259 11.5552 27.066 6.15626C24.9949 5.14799 22.7977 4.42274 20.5333 4ZM10.7263 20.8508C9.92703 20.7934 9.18106 20.4286 8.64499 19.833C8.10893 19.2374 7.82446 18.4572 7.8513 17.6563C7.82041 16.8544 8.10348 16.072 8.64035 15.4755C9.17722 14.879 9.92556 14.5153 10.7263 14.4619C11.5271 14.5153 12.2754 14.879 12.8123 15.4755C13.3492 16.072 13.6322 16.8544 13.6013 17.6563C13.6322 18.4583 13.3492 19.2407 12.8123 19.8372C12.2754 20.4337 11.5271 20.7973 10.7263 20.8508ZM21.3319 20.8508C20.5327 20.7934 19.7867 20.4286 19.2506 19.833C18.7146 19.2374 18.4301 18.4572 18.4569 17.6563C18.426 16.8544 18.7091 16.072 19.246 15.4755C19.7828 14.879 20.5312 14.5153 21.3319 14.4619C22.1342 14.5113 22.8849 14.8738 23.4226 15.4712C23.9603 16.0687 24.242 16.8533 24.207 17.6563C24.242 18.4593 23.9603 19.244 23.4226 19.8414C22.8849 20.4389 22.1342 20.8013 21.3319 20.8508Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_107_350">
                    <rect width="32" height="32" fill={color} />
                </clipPath>
            </defs>
        </svg>

    )
}
