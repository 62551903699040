import { Box, Button, Center, Flex, Input, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { mobileCheck } from '../../../@utils/utils';
import { COLORS } from '../../../assets/colors';
import Header from '../../_components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import ExchangeInputSection from '../../home/_components/ExchangeInputSection';
import { FONT } from '../../../assets/fonts';
import useSelectedCoin from '../../../hooks/useSelectedCoin';
import AlertIcon from '../../../assets/icon/AlertIcon';
import ArrowIcon from '../../../assets/icon/ArrowIcon';
import StatusSection from '../_component/StatusSection';
import { useRecoilValue } from 'recoil';
import { language, textData } from '../../../atom/language';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getAddressVerify } from '../../../api/request';
import { AddressVerifyResponse, ExchangeFeeResponse, ExchangePriceResponse } from '../../../@types/responseTypes';

export default function ExchangeNew() {

    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const lang = useRecoilValue(language);
    const { selectedCoinValue, receiveCoinValue } = useSelectedCoin();
    const sendFeeData = queryClient.getQueryData<ExchangeFeeResponse>(['exchange_fee_send', selectedCoinValue.symbol]);
    const receiveFeeData = queryClient.getQueryData<ExchangeFeeResponse>(['exchange_fee_receive', receiveCoinValue.symbol]);

    if (!location.state || !sendFeeData || !receiveFeeData) {
        navigate('/', { replace: true })
    }

    const [requestPrice, setRequestPrice] = useState<number>(location.state?.requestPrice as number ?? 0.1);
    const [inputEnable, setInputEnable] = useState<boolean>(false);
    const [reFoundOptionOpen, setRefoundOptionOpen] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const { ExchangeNew: text } = useRecoilValue(textData);
    const refoundInputRef = useRef<HTMLInputElement>(null);


    const { mutate, isPending } = useMutation<AddressVerifyResponse | undefined>({
        mutationKey: ['address_verify'],
        mutationFn: () => getAddressVerify({ address: inputRef?.current?.value as string, network_name: receiveCoinValue?.network.name }),
        onSuccess(data) {
            if (data?.is_valid) {
                if (!refoundInputRef.current?.value) {
                    refoundMutate();
                } else {
                    navigate('/exchange/confirmation', {
                        state: {
                            requestPrice,
                            selectedCoinValue,
                            receiveCoinValue,
                            receiveAddress: inputRef.current?.value,
                            refoundAddress: refoundInputRef.current?.value
                        },
                        replace: true
                    })
                }
            } else {
                toast(text[7](), { type: 'error' });
            }
        },
        onError(err) {
            console.log(err, 'err')
        }
    })

    const { mutate: refoundMutate } = useMutation<AddressVerifyResponse | undefined>({
        mutationKey: ['address_verify'],
        mutationFn: () => getAddressVerify({ address: inputRef?.current?.value as string, network_name: receiveCoinValue?.network.name }),
        onSuccess(data) {
            if (data?.is_valid) {
                navigate('/exchange/confirmation', {
                    state: {
                        requestPrice,
                        selectedCoinValue,
                        receiveCoinValue,
                        receiveAddress: inputRef.current?.value,
                        refoundAddress: refoundInputRef.current?.value
                    },
                    replace: true
                })
            } else {
                toast(text[8](), { type: 'error' });
            }
        }
    })


    const onInputFocus = () => {
        setInputEnable(true);

        setTimeout(() => {
            inputRef.current?.focus();
        }, 500)
    }

    const onSubmit = () => {
        console.log(selectedCoinValue, receiveCoinValue, 'receiveCoinValue')
        const anticipated_price = queryClient.getQueryData<ExchangePriceResponse>(['anticipated_price', `${requestPrice}`, `${receiveCoinValue?.symbol}`]);
        // const sendFeeData = queryClient.getQueryData<ExchangeFeeResponse>(['exchange_fee_send', selectedCoinValue.symbol]);
        // const receiveFeeData = queryClient.getQueryData<ExchangeFeeResponse>(['exchange_fee_receive', receiveCoinValue.symbol]);

        console.log(anticipated_price, 'anticipated_price')

        if (anticipated_price) {
            if (requestPrice < anticipated_price!.deposit_min_amount) {
                if (lang === 'ko') {
                    return toast(`최소 입금액은 ${anticipated_price!.deposit_min_amount} ${selectedCoinValue.symbol} 입니다`, { type: 'error' })
                } else {
                    return toast(`Minimum amount is ${anticipated_price!.deposit_min_amount} ${selectedCoinValue.symbol}`, { type: 'error' })
                }
            }

            if (anticipated_price?.estimate_amount < anticipated_price!.withdrawal_min_amount) {
                if (lang === 'ko') {
                    return toast(`최소 출금액은 ${anticipated_price!.withdrawal_min_amount} ${receiveCoinValue.symbol} 입니다`, { type: 'error' })
                } else {
                    return toast(`Minimum withdrawal amount is ${anticipated_price!.withdrawal_min_amount} ${receiveCoinValue.symbol}`, { type: 'error' })
                }
            }
        }
        if (!requestPrice || !inputRef.current?.value) return;
        mutate();

    }

    return (
        <Flex minW={'100%'} minH={'100dvh'} flexDirection={'column'}>
            <Header />
            <Flex flex={1} px={isWeb ? 0 : 5} flexDirection={'column'} background={`linear-gradient(to right, ${COLORS.background_color.join(',')})`} justifyContent={'center'} alignItems={'center'}>
                <Flex w={isWeb ? 1200 : 'full'} backgroundColor={COLORS.white} h={isWeb ? 'fit-content' : '530px'} borderRadius={15} boxShadow={'xl'} paddingX={5} flexDirection={'column'} py={5}>
                    <Center mb={10}>
                        <Text fontSize={isWeb ? 40 : 15} fontFamily={FONT.TTHakgyoansimSamulhamR}>
                            {text[1]()}
                        </Text>
                    </Center>
                    {
                        isWeb && <StatusSection />
                    }
                    <ExchangeInputSection requestPrice={requestPrice} setRequestPrice={setRequestPrice} />
                    <Flex w={'full'} h={isWeb ? 70 : '60px'} mt={10} flexDirection={'column'}>
                        <Flex
                            flex={1}
                            backgroundColor={COLORS.light_gray}
                            borderTopRadius={10}
                            flexDirection={'column'}
                            justifyContent={'space-around'}
                            position={'relative'}
                            onClick={onInputFocus}
                        >
                            <Text color={COLORS.gray} fontWeight={'bold'} fontSize={isWeb ? inputEnable ? 13 : 17 : inputEnable ? 10 : 13} left={3} position={'absolute'} zIndex={5} top={isWeb ? inputEnable ? '5%' : '20px' : inputEnable ? '5px' : '17px'} transition={'all .5s ease-in-out'}>
                                {text[2](receiveCoinValue.symbol)}
                            </Text>
                            {
                                inputEnable && (
                                    <Input
                                        position={'absolute'}
                                        bottom={isWeb ? '5%' : 0}
                                        ref={inputRef}
                                        left={isWeb ? 3 : 3}
                                        padding={0}
                                        _focusVisible={{ outline: 'none' }}
                                        border={'none'}
                                        fontSize={isWeb ? 17 : 13}
                                        w={isWeb ? '70%' : '90%'}
                                    />
                                )
                            }
                        </Flex>
                    </Flex>
                    <Flex w={'full'} h={isWeb ? 50 : 30} backgroundColor={'#FFEFCA'} borderBottomRadius={10} alignItems={'center'} px={3}>
                        <AlertIcon
                            size={isWeb ? 20 : 15}
                        />
                        <Text ml={3} fontSize={isWeb ? 14 : 9}>
                            {text[3](receiveCoinValue.symbol)}
                        </Text>
                    </Flex>
                    <Flex w={'full'} my={5} alignItems={'center'}>
                        <Box cursor={'pointer'} onClick={() => setRefoundOptionOpen(!reFoundOptionOpen)}>
                            <ArrowIcon
                                direction={reFoundOptionOpen ? 'up' : 'down'}
                                color={!reFoundOptionOpen ? COLORS.gray : COLORS.black}
                            />
                        </Box>
                        <Text color={reFoundOptionOpen ? COLORS.black : COLORS.gray} fontWeight={'bold'} fontSize={isWeb ? 17 : 13} mx={3}>
                            {text[4]()}
                        </Text>
                        <Box flex={1} h={.5} bgColor={COLORS.light_gray} />
                    </Flex>
                    {
                        reFoundOptionOpen &&
                        <Flex w={'full'} h={isWeb ? 70 : '60px'} backgroundColor={COLORS.light_gray} borderRadius={10}>
                            <Input
                                p={3}
                                placeholder={text[5]()}
                                ref={refoundInputRef}
                                flex={1}
                                h={'full'}
                                border={'none'}
                                _focusVisible={{ outline: 'none' }}
                                _placeholder={{ fontSize: isWeb ? 17 : 13 }}
                            />
                        </Flex>
                    }
                    <Button mt={5} colorScheme='whatsapp' height={isWeb ? 50 : '45px'} onClick={onSubmit} isLoading={isPending}>
                        {text[6]()}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}
