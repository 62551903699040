"use client"

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import axios, { AxiosError } from 'axios'
import React, { ReactNode, useState } from 'react'
import { toast } from 'react-toastify'

type Props = {
    children: ReactNode
}

const onErrorHandler = (errorCode: number) => {
    console.log(errorCode, '에러코드')
    if (errorCode === 404 || errorCode === 0) {
        window.location.href = '/error';
    }
}

export default function ReactQueryProvider({ children }: Props) {
    const [client] = useState(
        new QueryClient(
            {
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        retry: 0,
                    },
                },
                queryCache: new QueryCache({
                    onError: (error) => {
                        console.log(error, '에러객체')
                        if (axios.isAxiosError(error)) {
                            onErrorHandler(error.response?.status || 0)
                        }
                    },
                }),
                mutationCache: new MutationCache({
                    onError: (error) => {
                        console.log(error, '에러객체')
                        if (axios.isAxiosError(error)) {
                            onErrorHandler(error.response?.status || 0)
                        }
                    }
                })
            },
        )
    )

    return (
        <QueryClientProvider client={client}>
            {children}
            <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
    )
}
