import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { COLORS } from '../../../assets/colors'
import StarIcon from '../../../assets/icon/StarIcon'
import { mobileCheck } from '../../../@utils/utils'

interface Props {
    exchange_id: string,
    nickname: string,
    score: number,
    content: string,
    email_address: string
}

export default function ReviewBox({ exchange_id, nickname, score, content, email_address }: Props) {
    const [isWeb] = useMediaQuery(mobileCheck, { fallback: true });

    const getRating = () => {
        let ratingArr = [COLORS.gray, COLORS.gray, COLORS.gray, COLORS.gray, COLORS.gray];

        for (let i = 0; i < score; i++) {
            ratingArr[i] = '#FFD400'
        }
        return ratingArr
    }

    return (
        <Flex w={isWeb ? 300 : '100%'} h={300} backgroundColor={COLORS.white} flexDirection={'column'} borderRadius={10} boxShadow={'xl'} p={5}>
            <Text fontSize={15} fontWeight={'bold'}>
                {nickname}
            </Text>
            <Flex my={3} w={'50%'} justifyContent={'space-between'}>
                {
                    getRating().map((color, index) => (
                        <StarIcon key={index} color={color} />
                    ))
                }
            </Flex>
            <Flex flex={1} alignItems={'center'}>
                <Text fontSize={13}>
                    {content}
                </Text>
            </Flex>
        </Flex>
    )
}
